export class ItemPermissions {


    constructor(private readonly _canAdd: boolean,
                private readonly _canDelete: boolean,
                private readonly _canEdit: boolean,
                private readonly _canView: boolean,) {
    }

    get canView(): boolean {
        return this._canView;
    }

    get canEdit(): boolean {
        return this._canEdit;
    }

    get canAdd(): boolean {
        return this._canAdd;
    }

    get canDelete(): boolean {
        return this._canDelete;
    }
}
