import {Classifier} from "./classifier.model";
import {ClassifiersResponseMetaData} from "./classifiers-response-meta-data.model";

/**
 *  Classifiers loading Response data including metaData (count of items ...) and Classifiers Data
 */
export class ClassifiersResponse {

    constructor(private metaData: ClassifiersResponseMetaData,
                private data: Array<Classifier>) {
    }

    public getMetaData(): ClassifiersResponseMetaData {
        return this.metaData;
    }

    public getData(): Array<Classifier> {
        return this.data;
    }
}