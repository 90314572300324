import {FilterCriteria} from "./filter-criteria.model";
import {FilterOperator} from "./filter-type.model";


export class CombinedFilterCriteria implements FilterCriteria {

    protected operator: FilterOperator;

    protected filterCriteriaList: Array<FilterCriteria>;

    constructor(operator: FilterOperator, filterCriteriaList: Array<FilterCriteria>){
        this.operator = operator;
        this.filterCriteriaList = filterCriteriaList;
    }

    public toJson(): any {
        if(this.filterCriteriaList.length === 0){
            return {};
        }
        else if(this.filterCriteriaList.length === 1){
            return this.filterCriteriaList[0].toJson();
        }
        else{
            let filterCriteriaJson = {};
            let filterOperatorSerializedValue = this.getFilterOperatorSerializedValue();
            filterCriteriaJson[filterOperatorSerializedValue] = [];
            for(let filterCriteria of this.filterCriteriaList){
                filterCriteriaJson[filterOperatorSerializedValue].push(filterCriteria.toJson());
            }
            return filterCriteriaJson;
        }
    }

    public addFilterCriteria(filterCriteria: FilterCriteria) : void {
        this.filterCriteriaList.push(filterCriteria);
    }

    protected getFilterOperatorSerializedValue(): string {
        switch (this.operator){
            case FilterOperator.AND : {
                return "$and";
            }
            case FilterOperator.OR : {
                return "$or";
            }
            default : {
                throw new Error(`Unsupported Operator type ${this.operator} for combined filters.`);
            }

        }
    }


}
