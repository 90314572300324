
import {MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";
import {PropertyValue, EntityDefault} from "@synisys/idm-de-core-frontend";

/**
 * Model class for Classifier entity with getMultilingualName method
 */
export class Classifier extends EntityDefault {

    public static CLASSIFIER_FIELD_NAME_OF_NAME: string = "name";
    public static CLASSIFIER_FIELD_NAME_POSTFIX_INSTANCE_ID: string = "InstanceId";

    constructor(properties: Map<string, PropertyValue<any>>, identityFieldSystemName: string, instanceFieldSystemName : string){
        super(properties, identityFieldSystemName, instanceFieldSystemName);
    }

    /**
     * @returns {MultilingualString}
     * @deprecated not correct use classifierService.getEntityName
     */
    public getMultilingualName(): MultilingualString {
        return this.getProperty<MultilingualString>(Classifier.CLASSIFIER_FIELD_NAME_OF_NAME).value;
    }

    public clone<T=Classifier>(): T {
        return this.cloneEntity(Classifier);
    }
}

