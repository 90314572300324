import "rxjs/add/operator/toPromise";
import {Observable} from "rxjs/Observable";
import {PagingOptions, SortingOptions} from "@synisys/idm-de-core-frontend";
import {ClassifiersResponse} from "../model/classifiers-response.model";
import {ClassifierView} from "../model/classifier-view.model";
import {Classifier} from "../model/classifier.model";
import {ClassifierResponse} from "../model/classifier-response.model";
import {Validation} from "@synisys/idm-validation-calculation-service-client-js";
import {FilterCriteria} from "../helper/filter/filter-criteria.model";
import {Entity} from "@synisys/idm-de-core-frontend/app/shared/api/model/entity.model";
import {ItemPermissions} from "../model/classifier-item-permission.model";

/**
 * Abstract Class Interface responsible for loading deleted entities from audit service.
 *
 * @author Anania.Mikaelyan
 */
export abstract class EntityAuditService {



    /**
     * get Entities Last Versions from audit service
     * @param categorySystemName category system name of requested classifier
     * @param ids identifiers of requested classifiers which must be loaded from audit service
     */
    public abstract getEntitiesLastVersions(categorySystemName: string, ids: Array<number>): Observable<any>;

    /**
     * getEntity Last Versions from audit service
     * @param categorySystemName category system name of requested classifier
     * @param id identifier of requested classifier which must be loaded from audit service
     */
    public abstract getEntityLastVersion(categorySystemName: string, id: number): Observable<any>;


}

