import {FilterCriteria} from "./filter-criteria.model";
import {FilterOperator} from "./filter-type.model";
import {CombinedFilterCriteria} from "./combined-filter-criteria.model";
import {InFilterCriteria} from "./in-filter-criteria.model";
import {RangeFilterCriteria} from "./range-filter-criteria.model";
import {ComparisonFilterCriteria} from "./comparison-filter-criteria.model";
import {LikeFilterCriteria} from "./like-filter-criteria.model";
import {IsFilterCriteria} from "./is-filter-criteria.model";
import {NotFilterCriteria} from "./not-filter-criteria.model";
import {IntersectionFilterCriteria} from "./intersection-filter-criteria.model";

export class FilterBuilder {

    private filterCriteria: CombinedFilterCriteria = null;


    constructor(){
        this.filterCriteria = new CombinedFilterCriteria(FilterOperator.AND, []);
    }

    public and(filterCriteria: FilterCriteria) : FilterBuilder {
        if(this.filterCriteria === null){
            throw new Error("And operator cannot be called on one filterCriteria. It should be chained.")
        }
        this.filterCriteria.addFilterCriteria(filterCriteria);
        return this;
    }

    public or(filterCriteria: FilterCriteria) : FilterBuilder {
        if(this.filterCriteria === null){
            throw new Error("Or operator cannot be called on one filterCriteria. It should be chained.")
        }
        let orFilterCriteria = new CombinedFilterCriteria(FilterOperator.OR, [this.filterCriteria, filterCriteria]);
        let andFilterCriteria = new CombinedFilterCriteria(FilterOperator.AND, [orFilterCriteria]);
        this.filterCriteria = andFilterCriteria;
        return this;
    }

    public not(filterCriteria: FilterCriteria) : FilterBuilder {
        let notFilterCriteria = new NotFilterCriteria(FilterOperator.NOT, filterCriteria);
        this.filterCriteria.addFilterCriteria(notFilterCriteria);
        return this;
    }

    public in(key: string, value: Array<number>) : FilterBuilder {
        let filterCriteria = new InFilterCriteria(key, FilterOperator.IN, value);
        this.filterCriteria.addFilterCriteria(filterCriteria);
        return this;
    }

    public intersection(key: string, value: Array<number>) : FilterBuilder {
        let filterCriteria = new IntersectionFilterCriteria(key, FilterOperator.INTERSECTION, value);
        this.filterCriteria.addFilterCriteria(filterCriteria);
        return this;
    }

    public range(key: string, startValue: number, endValue: number) : FilterBuilder {
        let filterCriteria = new RangeFilterCriteria(key, FilterOperator.RANGE, [startValue, endValue]);
        this.filterCriteria.addFilterCriteria(filterCriteria);
        return this;
    }

    public lte(key: string, value: number) : FilterBuilder {
        let filterCriteria = new ComparisonFilterCriteria(key, FilterOperator.LTE, value);
        this.filterCriteria.addFilterCriteria(filterCriteria);
        return this;
    }

    public lt(key: string, value: number) : FilterBuilder {
        let filterCriteria = new ComparisonFilterCriteria(key, FilterOperator.LT, value);
        this.filterCriteria.addFilterCriteria(filterCriteria);
        return this;
    }

    public gte(key: string, value: number) : FilterBuilder {
        let filterCriteria = new ComparisonFilterCriteria(key, FilterOperator.GTE, value);
        this.filterCriteria.addFilterCriteria(filterCriteria);
        return this;
    }

    public gt(key: string, value: number) : FilterBuilder {
        let filterCriteria = new ComparisonFilterCriteria(key, FilterOperator.GT, value);
        this.filterCriteria.addFilterCriteria(filterCriteria);
        return this;
    }

    public starts(key: string, value: string) : FilterBuilder {
        let filterCriteria = new LikeFilterCriteria(key, FilterOperator.STARTS, value);
        this.filterCriteria.addFilterCriteria(filterCriteria);
        return this;
    }

    public contains(key: string, value: string) : FilterBuilder {
        let filterCriteria = new LikeFilterCriteria(key, FilterOperator.CONTAINS, value);
        this.filterCriteria.addFilterCriteria(filterCriteria);
        return this;
    }

    public is(key: string, value: string | number) : FilterBuilder {
        let filterCriteria = new IsFilterCriteria(key, FilterOperator.IS, value);
        this.filterCriteria.addFilterCriteria(filterCriteria);
        return this;
    }


    public build() : FilterCriteria {
        return this.filterCriteria;
    }

    public buildJson() : FilterCriteria {
        return this.filterCriteria.toJson();
    }


}
