import {ValidationsCalculationsData} from "@synisys/idm-validation-calculation-service-client-js";
import {Classifier} from "./classifier.model";


/**
 * Model class including classifier with calculated data, Validation Errors and Calculations Warnings data
 * Response data populated from server after classifier modification actions (create, update)
 */
export class ClassifierResponse {

    constructor(private classifier: Classifier,
                private validationsCalculationsData: ValidationsCalculationsData) { }

    public getClassifier(): Classifier {
        return this.classifier;
    }

    public getValidationsCalculationsData(): ValidationsCalculationsData {
        return this.validationsCalculationsData;
    }
}