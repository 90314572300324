import {Injectable, NgZone, Optional} from "@angular/core";

import {CurrentLanguageProvider} from "@synisys/idm-session-data-provider-api-js";

import {ApplicationPropertiesService} from "@synisys/idm-application-properties-service-client-js";
import {PreconditionCheck, StringTemplate} from "@synisys/idm-common-util-frontend";
import {LanguageService, MessageService} from "@synisys/idm-message-language-service-client-js";
import {
    deserializeClassifier,
    deserializeClassifierResponse,
    deserializeClassifiersModificationResponse,
    deserializeClassifiersResponse,
    deserializeClassifiersView,
    deserializeClassifierViews,
    deserializeDummyClassifier
} from "./cs-deserialization-helper";
import {serializeEntity} from "./cs-serialization-helper";
import {AuthenticationService, HttpClientWrapper, UserData} from "@synisys/idm-authentication-client-js";
import {Language} from "@synisys/idm-crosscutting-concepts-frontend";
import {HttpErrorResponse, HttpParams} from '@angular/common/http';
import {
    KbService,
    MetaCategory,
    MetaCategoryId,
    MetaField,
    MetaFieldId,
    MetaFieldType
} from "@synisys/idm-kb-service-client-js";
import {PagingOptions, SortingOptions, stringifyFieldValue} from "@synisys/idm-de-core-frontend";
import {ClassifierService} from "../api/classifier.service";
import {ClassifiersResponse} from "../model/classifiers-response.model";
import {Classifier} from "../model/classifier.model";
import {ClassifierResponse} from "../model/classifier-response.model";
import {ClassifierView} from "../model/classifier-view.model";
import {Validation, ValidationService} from "@synisys/idm-validation-calculation-service-client-js";
import {FilterCriteria} from "../helper/filter/filter-criteria.model";
import {FilterBuilder} from "../helper/filter/filter-builder";
import {AsyncLocalStorage} from "angular-async-local-storage";

import {Entity} from "@synisys/idm-de-core-frontend/app/shared/api/model/entity.model";
import {switchMap} from "rxjs/operators/switchMap";
import {ItemPermissions} from "../model/classifier-item-permission.model";
import {map, mergeMap, shareReplay, startWith, take, takeUntil} from "rxjs/operators";
import "rxjs/add/operator/concatMap";
import "rxjs/add/operator/first";
import "rxjs/add/observable/zip";
import 'rxjs/add/observable/interval';
import {ReplaySubject} from "rxjs/ReplaySubject";
import {Subject} from "rxjs/Subject";
import "rxjs/add/operator/toPromise";
import {EntityAuditService} from "../api/entity-audit.service";
import {EntityAuditHttpService} from "./entity-audit-http.service";
import {ClassifiersResponseMetaData} from "../model/classifiers-response-meta-data.model";
import {Observable} from "rxjs/Observable";
import {ClassifiersBulkResponse} from "../model/classifiers-bulk-response.model";
import {LocaleService} from '@synisys/idm-locale-service-client-js';
import {of} from 'rxjs/observable/of';
import {fromPromise} from 'rxjs/observable/fromPromise';

@Injectable()
export class ClassifierHttpService implements ClassifierService {

    public URL_CLASSIFIER_LOAD: StringTemplate = StringTemplate.createTemplate`${"serviceUri"}/classifiers/${"category"}/${"entityId"}`;
    public URL_CLASSIFIERS_LOAD: StringTemplate = StringTemplate.createTemplate`${"serviceUri"}/classifiers/${"category"}`;
    public URL_CLASSIFIERS_LOAD_BY_PARENT: StringTemplate = StringTemplate.createTemplate`${"serviceUri"}/classifiers/${"category"}/parent/${"parentId"}`;
    public URL_CLASSIFIERS_VIEW_LOAD: StringTemplate = StringTemplate.createTemplate`${"serviceUri"}/classifiers/${"category"}/view/${"languageId"}`;
    public URL_CLASSIFIERS_VIEW_LOAD_BY_PARENT: StringTemplate = StringTemplate.createTemplate`${"serviceUri"}/classifiers/${"category"}/view/${"languageId"}/${"parentId"}`;
    public URL_CLASSIFIERS_ICONS: StringTemplate = StringTemplate.createTemplate`${"serviceUri"}/classifiers/iconids/${"category"}`;
	public URL_CLASSIFIER_SAVE: StringTemplate = StringTemplate.createTemplate`${"serviceUri"}/classifiers/${"category"}`;
    public URL_CLASSIFIER_DELETE: StringTemplate = StringTemplate.createTemplate`${"serviceUri"}/classifiers/${"category"}/${"entityId"}`;
    public URL_CLASSIFIER_DOCUMENT_AUTH: StringTemplate = StringTemplate.createTemplate`${"serviceUri"}/token/${"category"}/${"id"}/${"systemName"}?permissionType=${"permissionType"}`;
    public URL_CLASSIFIER_PERMISSIONS_BY_ID: StringTemplate =
        StringTemplate.createTemplate`${'serviceUri'}/classifier/permission/${'category'}/${'classifierId'}/${'userId'}`;

    public URL_CLASSIFIER_LAST_MODIFIED_DATE: StringTemplate = StringTemplate.createTemplate`${"serviceUri"}/classifiers/modification/${"category"}`;
    public URL_MODIFIED_CATEGORIES: StringTemplate = StringTemplate.createTemplate`${"serviceUri"}/classifiers/modification`;

    public LAST_CALL_TO_UPDATE_CACHE: string = "cacheLastUpdatedTime";
    public MODIFICATION_TYPE_ADD: string = "ADD";
    public MODIFICATION_TYPE_EDIT: string = "EDIT";

    private TIME_TO_WAIT_FOR_MODIFIED_CATEGORIES: any = 20000;     // 1 minute

    private classifierServiceUriKey: string = "classifier-service-url";
    private classifierServiceUri$: Observable<string>;
    private languages$: Observable<Array<Language>>;
    private language$: Observable<Language>;
    private authenticatedUserData$: Observable<UserData>;


    private cacheItemsStatus: Map<string, CacheItemStatus> = new Map();
    private loadingCacheItems: Map<string, ReplaySubject<ClassifiersResponse>> = new Map();

    private isLocalStorageSupported: ReplaySubject<boolean> = new ReplaySubject<boolean>();

    private destroy$: Subject<boolean> = new Subject<boolean>();


    public constructor(private http: HttpClientWrapper,
                       private storage: AsyncLocalStorage,
                       private applicationPropertiesService: ApplicationPropertiesService,
                       public kbService: KbService,
                       private currentLanguageProvider: CurrentLanguageProvider,
                       private languageService: LanguageService,
                       private validationService: ValidationService,
                       private authenticationService: AuthenticationService,
                       private ngZone: NgZone,
                       private messageService: MessageService,
                       private localeService: LocaleService,
                       @Optional() private entityAuditService: EntityAuditService) {
        if (!entityAuditService) {
            this.entityAuditService = new EntityAuditHttpService(this.http, this.applicationPropertiesService, this.kbService, this.languageService);
        }
        this.language$ = this.currentLanguageProvider.getCurrentLanguage().pipe(shareReplay(1));
        this.languages$ = this.languageService.getAvailableLanguages().pipe(shareReplay(1));
        this.classifierServiceUri$ = Observable.from(this.applicationPropertiesService.getProperty(this.classifierServiceUriKey)).pipe(shareReplay(1));
        this.authenticatedUserData$ = this.authenticationService.getUserData().pipe(shareReplay(1));

        this.initAsyncLocalStorageSupportChecking();
        this.checkCacheCompatibility().subscribe(() => {
                this.initCheckClassifierModificationScheduler();
            }
        )
    }

    public loadAllClassifiers(categorySystemName: string, isWithChildren?: boolean, paging?: PagingOptions): Observable<ClassifiersResponse> {
        return Observable.zip(this.kbService.getMetaCategoryByMetaCategoryId(new MetaCategoryId(categorySystemName)), this.isLocalStorageSupported, this.getApplicationName())
            .flatMap((data : any) => {
                let metaCategory: MetaCategory = data[0];
                let isAsyncStorageSupported: boolean = data[1];
                let applicationName: string = data[2];
                let cacheKey: string = this.getCacheKey(applicationName, metaCategory.getSystemName(), isWithChildren);
                if (metaCategory.getIsCacheable() && isAsyncStorageSupported && !paging) {
                    return this.loadClassifiersFromCache(metaCategory.getSystemName(), cacheKey, isWithChildren);
                } else {
                    return this.loadClassifiersFromServer(metaCategory.getSystemName(), false, isWithChildren, paging);
                }
            });
    }

    public loadClassifiers(categorySystemName: string, isWithChildren?: boolean, paging?: PagingOptions,
                           sorting?: SortingOptions, filter?: any): Observable<ClassifiersResponse> {
        return this.loadAllClassifiers(categorySystemName, isWithChildren);
    }


    public loadClassifiersByIds(categorySystemName: string, filterIds: Array<number>, isWithChildren?: boolean, paging?: PagingOptions,
                                sorting?: SortingOptions): Observable<ClassifiersResponse> {
        return Observable
            .combineLatest(this.kbService.getMetaCategoryByMetaCategoryId(new MetaCategoryId(categorySystemName)),
                this.kbService.getMetaFields(categorySystemName), this.isLocalStorageSupported, this.languages$, this.getApplicationName())
            .flatMap((data : any) => {
                let metaCategory: MetaCategory = data[0];
                let metaFields: Array<MetaField> = data[1];
                let isAsyncStorageSupported: boolean = data[2];
                let languages: Array<Language> = data[3];
                let applicationName: string= data[4];
                //TODO improve performance .. at first check whether cachable and anly after get metaFields, isAsyncStorageSupported, languages
                if (isAsyncStorageSupported && metaCategory.getIsCacheable() && !sorting && !paging) {
                    let cacheKey: string = this.getCacheKey(applicationName, metaCategory.getSystemName(), isWithChildren);
                    return this.storage.getItem(cacheKey).flatMap((data: any) => {
                        if (data) {
                            return this.kbService.getIdentityMetaField(categorySystemName).pipe(
                                map(identityMetaField => this.filterClassifiersByIds(data, filterIds, identityMetaField)),
                                switchMap(filteredData => deserializeClassifiersResponse(metaFields, filteredData, categorySystemName, languages,
                                    !!isWithChildren, this, this.messageService, false)),
                                switchMap((filteredDeserializedData: ClassifiersResponse) => {
                                    return this.checkDeletedItemsExistenceAndAddToClassifiersResponse(categorySystemName, filterIds, isWithChildren,
                                        new ClassifiersResponse(filteredDeserializedData.getMetaData(), filteredDeserializedData.getData()));
                                }));

                        } else {
                            let cacheStatus: string = cacheKey + '-status-all-classifiers';
                            if (this.cacheItemsStatus.get(cacheStatus) == null) {
                                this.cacheItemsStatus.set(cacheStatus, CacheItemStatus.LOADING);
                                this.loadClassifiers(categorySystemName, isWithChildren).pipe(take(1)).subscribe(() => {
                                    this.cacheItemsStatus.set(cacheStatus, CacheItemStatus.LOADED);
                                });
                            }
                            return this.loadClassifiersByIdsFromServer(categorySystemName, metaFields, filterIds, isWithChildren, paging);
                        }
                    });
                } else {
                    return this.loadClassifiersByIdsFromServer(categorySystemName, metaFields, filterIds, isWithChildren, paging);
                }
            });
    }


    public loadClassifiersByFilter(categorySystemName: string, filterCriteria: FilterCriteria, isWithChildren?: boolean, paging?: PagingOptions,
                                   sorting?: SortingOptions): Observable<ClassifiersResponse> {
        return this.loadClassifiersByFilterFromServer(categorySystemName, filterCriteria, isWithChildren, paging)
    }

    public loadClassifiersViewByPaging(categorySystemName: string, filterCriteria: FilterCriteria, paging?: PagingOptions): Observable<ClassifierView[]> {
        PreconditionCheck.notNullOrUndefined(categorySystemName);

        const metaFields$: Observable<MetaField[]> = this.kbService.getClassifierViewMetaFields();
        return this.classifierServiceUri$
            .pipe(
                switchMap((classifierServiceUri: string) => this.getClassifierViewUrl(classifierServiceUri, categorySystemName)),
                switchMap((url: string) => {
                    const httpParams = this.getHttpParams(filterCriteria, paging);
                    return this.http.get(url, {params: httpParams});
                }),
                switchMap((data : any) => deserializeClassifierViews(metaFields$, data)),
            );
    }

    public loadClassifiersByParent(categorySystemName: string, parentId: number, paging?: PagingOptions,
                                   sorting?: SortingOptions, filter?: any, isWithChildren?: boolean): Observable<ClassifiersResponse> {
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIERS_LOAD_BY_PARENT.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName,
                    "parentId": parentId.toString()
                });
            });

        let metaFields$: Observable<Array<MetaField>> = this.kbService.getMetaFields(categorySystemName);
        let data$ = Observable.combineLatest(this.language$, url$)
            .flatMap((data : any) => {
                let httpParams: HttpParams = new HttpParams().set("languageId", (<Language>data[0]).getId().toString());
                return this.http.get(data[1], {params: httpParams});
            });
        return Observable.combineLatest(metaFields$, data$, this.languages$)
            .flatMap((data : any) => deserializeClassifiersResponse(data[0], data[1], categorySystemName, data[2], isWithChildren,
                this, this.messageService, false));
    }

    public loadClassifiersView(categorySystemName: string): Observable<Array<ClassifierView>> {
        return Observable.zip(this.kbService.getMetaCategoryByMetaCategoryId(new MetaCategoryId(categorySystemName)), this.language$, this.isLocalStorageSupported, this.getApplicationName())
            .flatMap((data : any) => {
                let metaCategory: any = data[0];
                let languageId: number = data[1].getId();
                let isAsyncStorageSupported: boolean = data[2];
                let applicationName: string = data[3];
                let cacheKey: string = this.getCacheKey(applicationName, metaCategory.getSystemName(), false, languageId);
                if (isAsyncStorageSupported && metaCategory.getIsCacheable()) {
                    return this.loadClassifierViewsFromCache(metaCategory.getSystemName(), this.loadClassifiersViewDataFromServer, cacheKey, languageId)
                } else {
                    return this.loadClassifiersViewFromServer(metaCategory.getSystemName(), languageId);
                }
            });
    }

    public loadClassifiersViewByFilter(categorySystemName: string, filter: FilterCriteria): Observable<Array<ClassifierView>> {
        return this.language$
            .flatMap((language: Language) => {
                return this.loadClassifiersViewByFilterFromServer(categorySystemName, filter, language.getId());
            });
    }

    public loadClassifiersViewByParent(categorySystemName: string, parentId: number): Observable<Array<ClassifierView>> {
        return Observable.zip(this.kbService.getMetaCategoryByMetaCategoryId(new MetaCategoryId(categorySystemName)), this.language$)
            .flatMap((data : any) => {
                let metaCategory: any = data[0];
                let languageId: number = data[1].getId();
                return this.loadClassifiersViewByParentFromServer(metaCategory.getSystemName(), languageId, parentId);
                
            });
    }

    public loadClassifier(categorySystemName: string, id: number, isWithChildren?: boolean): Observable<Classifier> {
        return Observable.zip(this.kbService.getMetaCategoryByMetaCategoryId(new MetaCategoryId(categorySystemName)),
            this.isLocalStorageSupported, this.getApplicationName())
            .flatMap((data : any) => {
                let metaCategory: any = data[0];
                let isAsyncStorageSupported: boolean = data[1];
                let applicationName: string = data[2];
                let cacheKey: string = this.getCacheKey(applicationName, metaCategory.getSystemName(), isWithChildren);
                if (isAsyncStorageSupported && metaCategory.getIsCacheable()) {
                    return this.loadClassifierFromCache(categorySystemName, id, isWithChildren, cacheKey);
                } else {
                    return this.loadAndDeserializeClassifier(categorySystemName, id, isWithChildren);
                }
            });
    }

    private loadClassifiersByIdsFromServer(categorySystemName: string, metaFields: Array<MetaField>, filterIds: Array<number>, isWithChildren?: boolean, paging?: PagingOptions): Observable<ClassifiersResponse> {
        if(filterIds.length === 0){
            return Observable.of(new ClassifiersResponse(new ClassifiersResponseMetaData(0),[]));
        }
        let idMetaField: MetaField = metaFields.find((metaField: MetaField) => metaField.getType() == MetaFieldType.INTEGER_IDENTITY);
        let filterBuilder: FilterBuilder = new FilterBuilder();
        let filter: FilterCriteria = filterBuilder.in(idMetaField.getSystemName(), filterIds).buildJson();
        let classifiersResponse$: Observable<ClassifiersResponse> = this.loadClassifiersByFilterFromServer(categorySystemName, filter, isWithChildren, paging);

        return classifiersResponse$.mergeMap((classifiersResponse: ClassifiersResponse) => {
            let resultIds: number[] = classifiersResponse.getData().reduce((acc: Array<number>, item: Classifier) => {
                acc.push(item.getId());
                return acc;
            }, []);
            let deletedClassifiersIds = filterIds.filter(id => resultIds.indexOf(id) < 0);
            if (deletedClassifiersIds.length > 0) {
                let metaFields$: Observable<Array<MetaField>> = this.kbService.getMetaFields(categorySystemName);
                let classifiersResponse$: Observable<ClassifiersResponse> = this.entityAuditService.getEntitiesLastVersions(categorySystemName, deletedClassifiersIds);
                return Observable.combineLatest(metaFields$, classifiersResponse$, this.languages$).flatMap((data: any) => {
                    return deserializeClassifiersResponse(data[0], data[1], categorySystemName, data[2], isWithChildren, this, this.messageService, true).map((deletedResponse: ClassifiersResponse) => {
                        classifiersResponse.getData().push(...deletedResponse.getData());
                        return classifiersResponse;
                    })
                });
            } else {
                return Observable.of(classifiersResponse);
            }

        });
    }

    private filterClassifiersByIds(responseData: any, filterIds: number[], identityMetaField: MetaField): any {
        responseData.data = responseData.data.filter((classifierJson: any) => {
            return filterIds.indexOf(classifierJson[identityMetaField.getSystemName()]) >= 0;
        });
        return responseData;
    }

    private checkDeletedItemsExistenceAndAddToClassifiersResponse(categorySystemName: string, filterIds: Array<number>, isWithChildren: boolean, existingClassifiersResponse: ClassifiersResponse): Observable<ClassifiersResponse> {
        let resultIds: number[] = existingClassifiersResponse.getData().reduce((acc: Array<number>, item: Classifier) => {
            acc.push(item.getId());
            return acc;
        }, []);
        let deletedClassifiersIds = filterIds.filter(id => resultIds.indexOf(id) < 0);
        if (deletedClassifiersIds.length > 0) {
            let metaFields$: Observable<Array<MetaField>> = this.kbService.getMetaFields(categorySystemName);
            let classifiersResponse$: Observable<ClassifiersResponse> = this.entityAuditService.getEntitiesLastVersions(categorySystemName, deletedClassifiersIds);
            return Observable.combineLatest(metaFields$, classifiersResponse$, this.languages$).flatMap((data: any) => {
                return deserializeClassifiersResponse(data[0], data[1], categorySystemName, data[2], isWithChildren, this, this.messageService, true).map((deletedResponse: ClassifiersResponse) => {
                    existingClassifiersResponse.getData().push(...deletedResponse.getData());
                    return existingClassifiersResponse;
                })
            });
        } else {
            return Observable.of(existingClassifiersResponse);
        }
    }


    private initCheckClassifierModificationScheduler() {
        this.authenticationService.getUserData().subscribe(value => {
            this.ngZone.runOutsideAngular(() => {
                this.destroy$.next(true);
                Observable.interval(this.TIME_TO_WAIT_FOR_MODIFIED_CATEGORIES).pipe(
                    startWith(0),
                    mergeMap(x => {
                        return this.checkAndUpdateCache();
                    }),
                    takeUntil(this.destroy$)
                ).subscribe(value => {
                }, error => {
                    if (error.status === 401) {
                        this.destroy$.next(true)
                    }
                });
            });
        });
    }


    public loadClassifierDependencies(categorySystemName: string, id: number): Observable<Classifier> {
        return this.loadClassifier(categorySystemName, id, true);
    }

    public loadCategoryIconIds(categorySystemName: string, ids: number[]): Observable<Map<number, number>> {
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIERS_ICONS.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName,
                });
            });
        let httpParams: HttpParams = new HttpParams().set("ids", ids.toString());
        return url$.flatMap((url: string) => {
            return this.http.get(url, {params: httpParams}).map(mapJson => {
                let convertedMap: Map<number, number> = new Map<number, number>();
                ids.forEach(id => {
                    convertedMap.set(id, mapJson[id.toString()]);
                });
                return convertedMap;
            });
        })

    }

    public createDummyClassifier(categoryName: string): Observable<Classifier> {
        let metaFields$: Observable<Array<MetaField>> = this.kbService.getMetaFields(categoryName);

        return Observable.combineLatest(metaFields$, this.languages$)
            .map((data : any) => deserializeDummyClassifier(data[0], categoryName, data[1]));
    }

    public create(categorySystemName: string, classifier: Classifier): Observable<ClassifierResponse> {
	    return this.saveClassifiers(this.MODIFICATION_TYPE_ADD, categorySystemName, [classifier], false, false).switchMap((data : any)=>{
		    let serverResponse: any = data[0];
		    let metaFields: Array<MetaField> = data[1];
		    let languages: Array<Language> = data[2];

		    return this.updateCaches([categorySystemName]).switchMap(_ => {
			    return deserializeClassifierResponse(serverResponse, metaFields, languages, categorySystemName, this, this.messageService);
		    });
	    });
    }

	public bulkCreate(categorySystemName: string, classifiers: Classifier[], raiseEvent=true): Observable<ClassifiersBulkResponse> {
		return this.saveClassifiers(this.MODIFICATION_TYPE_ADD, categorySystemName, classifiers, true, raiseEvent).switchMap((data : any)=>{
			let serverResponse: any = data[0];
			let metaFields: Array<MetaField> = data[1];
			let languages: Array<Language> = data[2];

			return this.updateCaches([categorySystemName]).switchMap(_ => {
				return deserializeClassifiersModificationResponse(metaFields, serverResponse, categorySystemName, languages,
					false, this, this.messageService);
			});
		});
	}



    public update(categorySystemName: string, classifier: Classifier): Observable<ClassifierResponse> {
	    return this.saveClassifiers(this.MODIFICATION_TYPE_EDIT, categorySystemName, [classifier], false, false).switchMap((data : any)=>{
		    let serverResponse: any = data[0];
		    let metaFields: Array<MetaField> = data[1];
		    let languages: Array<Language> = data[2];

		    return this.updateCaches([categorySystemName]).switchMap(_ => {
			    return deserializeClassifierResponse(serverResponse, metaFields, languages, categorySystemName, this, this.messageService);
		    });
	    });
    }

    public bulkUpdate(categorySystemName: string, classifiers: Classifier[], raiseEvent=true): Observable<ClassifiersBulkResponse> {
        return this.saveClassifiers(this.MODIFICATION_TYPE_EDIT, categorySystemName, classifiers, true, raiseEvent).switchMap((data : any)=>{
            let serverResponse: any = data[0];
            let metaFields: Array<MetaField> = data[1];
            let languages: Array<Language> = data[2];

            return this.updateCaches([categorySystemName]).switchMap(_ => {
                return deserializeClassifiersModificationResponse(metaFields, serverResponse, categorySystemName, languages,
                    false, this, this.messageService);
            });
        });
    }

    public delete(categorySystemName: string, classifier: Classifier): Observable<any> {
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIER_DELETE.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName,
                    "entityId": classifier.getId().toString()
                });
            });


        return Observable.combineLatest(url$, this.authenticatedUserData$).flatMap((data : any) => {
            let url: string = data[0];
            let userIdStr: string = data[1].userId.toString();
            return this.http.delete(url + `?userId=${userIdStr}`).flatMap((response: any) => {
                return this.updateCaches([categorySystemName]);
            });
        }).catch(this.handleError);
    }


    public bulkDelete(categorySystemName: string, ids: number[], raiseEvent=true): Observable<any> {
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIER_SAVE.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName
                });
            });


        return Observable.combineLatest(url$, this.authenticatedUserData$).flatMap((data : any) => {
            let url: string = data[0];
            let userIdStr: string = data[1].userId.toString();
            return this.http.request("DELETE", url + `?userId=${userIdStr}&type=bulk&raiseEvent=${raiseEvent}`, {body: ids})
                .flatMap((response: any) => {
                return this.updateCaches([categorySystemName]);
            });
        }).catch(this.handleError);
    }

    public getCategoryLastModificationTimeInMillis(categorySystemName: string): Observable<number> {
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIER_LAST_MODIFIED_DATE.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName
                });
            });
        return url$.flatMap((url: string) => {
            return this.http.get(url).map((modificationDate: any) => {
                return modificationDate;
            });
        }).catch(this.handleError);
    }

    public getModifiedCategories(modifiedAfter: number): Observable<any> {
        if (modifiedAfter == null) {
            return Observable.of([]);
        }
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_MODIFIED_CATEGORIES.replaceTemplate({
                    "serviceUri": classifierServiceUri
                });
            });
        return url$.mergeMap((url: string) => {
            let httpParams: HttpParams = new HttpParams().set("modifiedAfter", modifiedAfter.toString());
            return this.http.get(url, {params: httpParams});
        });
    }


    public getDocumentAuthInfo(categorySystemName: string, id: number, documentSystemName: string): Observable<string> {
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIER_DOCUMENT_AUTH.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName,
                    "id": id.toString(),
                    "systemName": documentSystemName,
                    "permissionType": "DOWNLOAD"
                });
            });
        return url$.flatMap((url: string) => {
            return this.http.get(url, {responseType: "text"})
                .catch(this.handleError);
        })
    }

    public getDocumentAuthInfoForUpload(categorySystemName: string, id: number, documentSystemName: string): Observable<string> {
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIER_DOCUMENT_AUTH.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName,
                    "id": id.toString(),
                    "systemName": documentSystemName,
                    "permissionType": "UPLOAD"
                });
            });
        return url$.flatMap((url: string) => {
            return this.http.get(url, {responseType: "text"})
                .catch(this.handleError);
        })
    }

    public getApplicationName(): Observable<string> {
        return  this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return classifierServiceUri.split("/").pop();
            });
    }

    public getAuthInfo(categorySystemName: string, id: number, documentSystemName: string): Observable<string> {
        return this.getDocumentAuthInfo(categorySystemName, id, documentSystemName);
    };


    public validateClassifier(categorySystemName: string, classifier: Classifier): Observable<Array<Validation>> {
        let metaFields$: Observable<Array<MetaField>> = this.kbService.getMetaFields(categorySystemName);
        return this.languages$.flatMap(languages => {
            return serializeEntity(classifier, metaFields$, languages, this.kbService);
        }).flatMap(serializedClassifier => {
            return this.validationService.validateEntity(categorySystemName, serializedClassifier);
        });
    }

    public validateClassifierField(categorySystemName: string, classifier: Classifier, fieldSystemName: string): Observable<Array<Validation>> {
        let metaFields$: Observable<Array<MetaField>> = this.kbService.getMetaFields(categorySystemName);
        return this.languages$.flatMap(languages => {
            return serializeEntity(classifier, metaFields$, languages, this.kbService);
        }).flatMap(serializedClassifier => {
            return this.validationService.validateField(categorySystemName, fieldSystemName, serializedClassifier);
        });
    }

    public getEntityName(categorySystemName: string, entity: Entity, separator: string = " "): Observable<any> {
        if(entity===null){
            return Observable.of(null)
        }
        return Observable.combineLatest(this.kbService.getNameMetaFieldIds(categorySystemName), this.language$)
            .flatMap((data: any) => {
                let nameMetaFieldIds: Array<MetaFieldId> = data[0];
                let language: Language = data[1];
                let names$: Observable<string>[] = [];
                nameMetaFieldIds.forEach(nameMetaFieldId => {
                    let nameMetaField$ = this.kbService.getMetaFieldByMetaFieldId(nameMetaFieldId);
                    names$.push(nameMetaField$.mergeMap(nameMetaField => {
                        const metaFieldType = nameMetaField.getType();
                        const fieldValue = entity.getProperty(nameMetaField.getSystemName()).value;
                        if (metaFieldType === MetaFieldType.DATE) {
                            return fromPromise(this.localeService.formatDate(fieldValue as number, language.getId()));
                        } else if (metaFieldType === MetaFieldType.DATE_TIME) {
                            return fromPromise(this.localeService.formatDateTime(fieldValue as number, language.getId()));
                        }
                        return of(stringifyFieldValue(nameMetaField, fieldValue, language));
                    }))
                });
                if (names$.length > 0) {
                    return Observable.zip(...names$).map((names: string[]) => names.filter(Boolean).join(separator));
                } else {
                    return Observable.of('');
                }
            });
    }


    public resetCaches(categorySystemName: string): void {
        this.clearCache(categorySystemName).subscribe();
    }

    public clearCache(categorySystemName?: string): Observable<boolean> {
        if (categorySystemName) {
            return this.getCacheKeysToClean(categorySystemName).flatMap((cacheKeys: string[]) => {
                return Observable.zip(...cacheKeys.map((cacheKey: string) => {
                    return this.storage.removeItem(cacheKey)
                })).map((_) => true);
            });
        } else {
            return this.clearWholeCache();
        }
    }

    private getCacheKeysToClean(categorySystemName: string): Observable<string[]> {
        return Observable.combineLatest(this.languages$, this.getApplicationName()).map((data: any) => {
            let languages: Array<Language> = data[0];
            let applicationName: string = data[1];
            let keys: string[] = [];
            languages.forEach((language: Language) => {
                keys.push(this.getCacheKey(applicationName, categorySystemName, false, language.getId()));
            });
            return keys.concat([this.getCacheKey(applicationName, categorySystemName, true),
                this.getCacheKey(applicationName, categorySystemName, false)])
        });
    }


    public loadItemPermissions(categorySystemName: string, itemId: number): Observable<ItemPermissions> {
        PreconditionCheck.notNullOrUndefined(categorySystemName);
        PreconditionCheck.notNullOrUndefined(itemId);
        return Observable.combineLatest(this.authenticationService.getUserData(), this.classifierServiceUri$)
            .mergeMap((data : any) => {
                const userData: UserData = data[0];
                const classifierServiceUri: string = data[1];
                return this.http.get(this.URL_CLASSIFIER_PERMISSIONS_BY_ID.replaceTemplate(
                    {
                        "serviceUri": classifierServiceUri,
                        "category": categorySystemName,
                        "classifierId": itemId.toString(),
                        "userId": userData.userId.toString()
                    }
                )).map((responce: any) => {
                    return new ItemPermissions(
                        responce.add && responce.add.permission,
                        responce.delete && responce.delete.permission,
                        responce.edit && responce.edit.permission,
                        responce.view && responce.view.permission);
                }).shareReplay(1)
                    .catch(this.handleError);
            })
    }

    public loadClassifiersByFilterFromServer(categorySystemName: string, filter: FilterCriteria, isWithChildren: boolean, paging: PagingOptions): Observable<ClassifiersResponse> {
        let filterJsonCriteria = {condition: filter};
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIERS_LOAD.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName
                });
            });

        let metaFields$: Observable<Array<MetaField>> = this.kbService.getMetaFields(categorySystemName);
        let data$ = Observable.combineLatest(this.language$, url$)
            .flatMap((data : any) => {
                let httpParams: HttpParams = new HttpParams()
                    .set("languageId", (<Language>data[0]).getId().toString())
                    .set("filterCriteria", JSON.stringify(filterJsonCriteria));
                if (paging) {
                    httpParams = httpParams.set("pagingCriteria", JSON.stringify(paging))
                }
                return this.http.get(data[1], {params: httpParams});
            });
        return Observable.combineLatest(metaFields$, data$, this.languages$)
            .flatMap((data : any) => {
                return deserializeClassifiersResponse(data[0], data[1], categorySystemName, data[2], isWithChildren, this, this.messageService, false)
            });
    }


    private loadClassifierViewsFromCache(categorySystemName: string, loadClassifierViewsMethod: any, cacheKey: string, languageId: number, parentId?: number): Observable<Array<ClassifierView>> {
        return this.storage.getItem(cacheKey).flatMap((data: any) => {
            if (data) {
                return this.kbService.getClassifierViewMetaFields().map((fields: any) => {
                    return deserializeClassifiersView(fields, data);
                });
            } else {
                return loadClassifierViewsMethod.apply(this, [categorySystemName, languageId, parentId])
                    .flatMap((newData: any) => {
                        return this.storage.setItem(cacheKey, newData)
                            .flatMap(
                                (data : any) => {
                                    this.updateLastModificationCallTime();
                                    return this.kbService.getClassifierViewMetaFields().map((fields: any) => {
                                        return deserializeClassifiersView(fields, newData);
                                    });
                                },
                            ).catch((e: any) => {
                                console.warn("IndexedDB entry with the same key already exists error catched.");
                                return this.kbService.getClassifierViewMetaFields().map((fields: any) => {
                                    return deserializeClassifiersView(fields, newData);
                                });
                            })
                    });
            }
        });
    }

    private loadClassifiersFromCache(categorySystemName: string, cacheKey: string, isWithChildren?: boolean, parentId?: number): Observable<ClassifiersResponse> {
        return this.storage.getItem(cacheKey).flatMap((data: any) => {
            if (data) {
                return Observable.combineLatest(this.kbService.getMetaFields(categorySystemName), this.languages$)
                    .flatMap(info => {
                        return deserializeClassifiersResponse(info[0], data, categorySystemName, info[1], isWithChildren ? isWithChildren : false, this, this.messageService, false).catch((e: any) => {
                            return this.handleErrorDuringDeserializationResponse(e, categorySystemName)
                        })
                    });
            } else {
                return this.loadClassifiersDataFromServer(categorySystemName, true, null)
                    .flatMap((newData: any) => {
                        return this.storage.setItem(cacheKey, newData)
                            .flatMap(storageSetItemStatus => {
                                    this.updateLastModificationCallTime();
                                    return Observable.combineLatest(this.kbService.getMetaFields(categorySystemName), this.languages$)
                                        .flatMap(info => {
                                            return deserializeClassifiersResponse(info[0], newData, categorySystemName, info[1], isWithChildren ? isWithChildren : false, this, this.messageService, false).catch((e: any) => {
                                                return this.handleErrorDuringDeserializationResponse(e, categorySystemName)
                                            })
                                        });
                                },
                            ).catch((e: any) => {
                                console.warn("IndexedDB entry with the same key already exists error catched.");
                                return Observable.combineLatest(this.kbService.getMetaFields(categorySystemName), this.languages$)
                                    .flatMap(info => {
                                        return deserializeClassifiersResponse(info[0], newData, categorySystemName, info[1], isWithChildren ? isWithChildren : false, this, this.messageService, false).catch((e: any) => {
                                            return this.handleErrorDuringDeserializationResponse(e, categorySystemName)
                                        })
                                    });
                            })
                    });
            }
        });
    }

    private filterAndFindClassifierFromCachedData(categorySystemName: string, id: number, isWithChildren: boolean, data: any): Observable<any> {
        let metaFields$: Observable<MetaField[]> = this.kbService.getMetaFields(categorySystemName);
        let identityMetaField$: Observable<MetaField> = this.kbService.getIdentityMetaField(categorySystemName);

        if (data.data && data.data.length == 0) {
            return Observable.of(null)
        }
        //let identityField: any = data.data[0].identityField; // TODO kbService.getIdentityMetaField.. check with gantt chart user
        let classifierData$: Observable<any> = identityMetaField$.map((identityMetaField: MetaField)=>{
               return  data.data.filter((classifier: any) => {
                   return classifier[identityMetaField.getSystemName()] == id;
               })[0];
        });


        return classifierData$.flatMap((classifierData:any)=>{
            if (!classifierData) {
                //load data from
                let classifierDataFromAudit$: any = this.entityAuditService.getEntityLastVersion(categorySystemName, id);
                return Observable.combineLatest(metaFields$, this.languages$, classifierDataFromAudit$)
                    .switchMap((data : any) => {
                        return deserializeClassifier(data[0], data[2], categorySystemName, data[1], this, this.messageService, isWithChildren, true)
                    });
            }else{
                return Observable.combineLatest(metaFields$, this.languages$)
                    .switchMap((data : any) => {
                        return deserializeClassifier(data[0], classifierData, categorySystemName, data[1], this, this.messageService, isWithChildren, false)
                    });
            }
        });



    }

    private loadClassifierFromCache(categorySystemName: string, id: number, isWithChildren: boolean, cacheKey: string): Observable<Classifier> {
        let cacheStatus: string = cacheKey + '-status';
        return this.storage.getItem(cacheKey).flatMap((data: any) => {
            if (data) {
                return this.filterAndFindClassifierFromCachedData(categorySystemName, id, isWithChildren, data);
            } else {
                if (this.cacheItemsStatus.get(cacheStatus) == null) {
                    this.cacheItemsStatus.set(cacheStatus, CacheItemStatus.LOADING);
                    let $listLoaded: ReplaySubject<any> = new ReplaySubject<any>(1);
                    this.loadingCacheItems.set(cacheKey, $listLoaded);
                    this.loadClassifiersDataFromServer(categorySystemName, true).subscribe((justLoadedData) => {
                        this.cacheItemsStatus.set(cacheStatus, CacheItemStatus.LOADED);
                        this.loadingCacheItems.get(cacheKey).next(justLoadedData);
                    });
                }
                if (this.cacheItemsStatus.get(cacheStatus) == CacheItemStatus.LOADING || this.cacheItemsStatus.get(cacheStatus) == CacheItemStatus.LOADED) {
                    return this.loadingCacheItems.get(cacheKey).switchMap((justLoaded: ClassifiersResponse) => {
                        return this.filterAndFindClassifierFromCachedData(categorySystemName, id, isWithChildren, justLoaded);
                    });
                }
            }
        });
    }

    private updateLastModificationCallTime(): void {
        let lastCallToGetModifiedCategories: any = window.localStorage.getItem(this.LAST_CALL_TO_UPDATE_CACHE);
        if (lastCallToGetModifiedCategories == null) {
            window.localStorage.setItem(this.LAST_CALL_TO_UPDATE_CACHE, new Date().getTime().toString());
        }
    }

    private checkAndUpdateCache(): Observable<any> {
        let lastCallToGetModifiedCategories: any = window.localStorage.getItem(this.LAST_CALL_TO_UPDATE_CACHE);
        if (lastCallToGetModifiedCategories == null) {
            window.localStorage.setItem(this.LAST_CALL_TO_UPDATE_CACHE, new Date().getTime().toString());
            return Observable.of(true);
        } else {
            return this.getModifiedCategories(lastCallToGetModifiedCategories)
                .flatMap((categories: Array<string>) => {
                    if (categories.length > 0) {
                        return this.updateCaches(categories);
                    } else {
                        return Observable.of(false);
                    }
                });
        }
    }

    private updateCaches(categories: Array<string>): Observable<boolean> {
        this.kbService.resetCache();
        let observables: Array<Observable<any>> = [];
        categories.forEach((categorySystemName: string) => {
            observables.push(this.clearCache(categorySystemName));
        });
        return this.resolveAllObservables(observables).map(() => {
            window.localStorage.setItem(this.LAST_CALL_TO_UPDATE_CACHE, new Date().getTime().toString());
            this.cacheItemsStatus.clear();
            return true;
        });
    }


    private checkCacheCompatibility(): Observable<any> {
        let cacheVersion: any = Number(window.localStorage.getItem("cacheVersion"));
        if (!cacheVersion) {
            // console.warn("Cache incompatibility: resetting");
            window.localStorage.setItem("cacheVersion", "2");
            return this.forceClearWholeIndexedDBCache();
        } else {
            return Observable.of("");
        }
    }

    private clearWholeCache(): Observable<any> {
        return this.kbService.getClassifierMetaCategories().pipe(
            switchMap((metaCategories: Set<MetaCategory>) => {
                const values: Array<Observable<any>> = Array.from(metaCategories)
                    .filter(metaCategory => metaCategory.getIsCacheable() && metaCategory.getIsClassifier())
                    .map(metaCategory => {
                        return this.getCacheKeysToClean(metaCategory.getSystemName()).pipe(
                            switchMap(cacheKeys => {
                                return Observable.combineLatest(cacheKeys.map(cacheKey => this.storage.removeItem(cacheKey))).map(_ => true);
                            }))
                    });
                return !!values.length ? Observable.combineLatest(values).map(_ => true) : Observable.of(true)
            }));
    }

    private forceClearWholeIndexedDBCache(): Observable<any> {
        console.warn("Force deleting whole indexedDB and classifiers local storage keys");
        let cacheKeys: any = window.localStorage.getItem("cacheKeys");
        return this.storage.clear().map((isSuccess : boolean)=>{
            console.warn("Force deleted whole indexedDB:" + isSuccess);
            for (let key in window.localStorage) {
                if (key == "cacheKeys" || key == "cacheLastUpdatedTime") {
                    window.localStorage.removeItem(key);
                }
            }
        });
    }

    private resolveAllObservables(observables: Array<Observable<any>>): Observable<any> {
        if (observables.length == 0) {
            return Observable.of(true);
        }
        return Observable.zip(...observables).map((result: any) => {
            return result;
        });
    }

    //todo paging...
    //? keep caches  for page, sort, filter
    private loadClassifiersFromServer(categorySystemName: string, isCacheable: boolean, isWithChildren?: boolean, paging?: PagingOptions): Observable<ClassifiersResponse> {
        PreconditionCheck.notNullOrUndefined(categorySystemName);
        let metaFields$: Observable<Array<MetaField>> = this.kbService.getMetaFields(categorySystemName);
        let url$ = Observable.combineLatest(this.language$, this.classifierServiceUri$)
            .map((data : any) => {
                let classifierServiceUri: string = data[1];
                return this.URL_CLASSIFIERS_LOAD.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName
                });
            });
        let data$ = this.loadClassifiersDataFromServer(categorySystemName, isCacheable, paging);
        return Observable.combineLatest(metaFields$, data$, this.languages$, this.language$, url$)
            .switchMap((data : any) => {
                let finalResponse: ClassifiersResponse;
                return deserializeClassifiersResponse(data[0], data[1], categorySystemName, data[2], isWithChildren, this, this.messageService, false).flatMap((response: ClassifiersResponse) => {
                    finalResponse = response;
                    if (response.getMetaData().getCount() > 1000 && isCacheable) {
                        let newPagingOptions: PagingOptions = new PagingOptions(response.getData().length, response.getMetaData().getCount() - 1000);
                        return this.loadClassifiersFromServer(categorySystemName, false, isWithChildren, newPagingOptions).flatMap((dataNext: any) => {
                            return deserializeClassifiersResponse(data[0], dataNext, categorySystemName, data[2], isWithChildren, this, this.messageService, false).map((secondResponse: ClassifiersResponse) => {
                                finalResponse.getData().push(...secondResponse.getData());
                                return finalResponse;
                            })
                        });
                    } else {
                        return Observable.of(finalResponse)
                    }
                });
            });

    }


    private loadClassifiersDataFromServer(categorySystemName: string, isCacheable: boolean, paging?: PagingOptions): Observable<any> {
        PreconditionCheck.notNullOrUndefined(categorySystemName);
        let url$ = Observable.combineLatest(this.language$, this.classifierServiceUri$)
            .map((data : any) => {
                let classifierServiceUri: string = data[1];
                return this.URL_CLASSIFIERS_LOAD.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName
                });
            });
        return Observable.combineLatest(this.language$, url$)
            .switchMap((data : any) => {
                return this.doGetCallForClassifiers(categorySystemName, data, paging, isCacheable);
            });

    }

    private doGetCallForClassifiers(categorySystemName: string, data: any, paging: PagingOptions, isCacheable: boolean): Observable<any> {
        const http: string = data[1];
        const language: Language = <Language>data[0];
        let httpParams: HttpParams = new HttpParams().set("languageId", language.getId().toString());
        if (paging && !isCacheable) {
            httpParams = httpParams.set("pagingCriteria", JSON.stringify(paging))
        }
        if (isCacheable) {
            return this.http.get(http, {params: httpParams}).pipe(switchMap((responseData: any) => {
                return (responseData.metaData.count > 1000)
                    ? this.loadAllCacheableClassifiersWithPaging(http, language.getId(), responseData.metaData.count, categorySystemName)
                    : Observable.of(responseData);
            }))
        } else {
            return this.http.get(http, {params: httpParams});
        }
    }

    /*Load with paging for cacheable classifiers is because of backend limitation for of cacheable classifiers load (1000)*/
    private loadAllCacheableClassifiersWithPaging(url: string, languageId: number, classifiersCount: number, categorySystemName: string): Observable<any> {
        console.warn("Cacheable classifier '" + categorySystemName + "' has more than 1000 items, must be changed to non cacheable");
        let httpParams: HttpParams = new HttpParams().set("languageId", languageId.toString());
        httpParams = httpParams.set("pagingCriteria", JSON.stringify(new PagingOptions(0, classifiersCount)));
        return this.http.get(url, {params: httpParams})
    }


    private getClassifierViewUrl(classifierServiceUri: string, categorySystemName: string): Observable<string> {
        return this.language$
            .map((language: Language) => this.URL_CLASSIFIERS_VIEW_LOAD.replaceTemplate({
                "serviceUri": classifierServiceUri,
                "category": categorySystemName,
                "languageId": language.getId().toString(),
            }));
    }


    private loadAndDeserializeClassifier(categoryName: string, id: number, isWithChildren: boolean): Observable<Classifier> {
        PreconditionCheck.notNullOrUndefined(id);
        let metaFields$: Observable<Array<MetaField>> = this.kbService.getMetaFields(categoryName);
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIER_LOAD.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categoryName,
                    "entityId": id.toString()
                });
            });

        let isClassifierDeleted: boolean = false;
        let data$ = Observable.combineLatest(this.language$, url$)
            .flatMap((data : any) => {
                let httpParams: HttpParams = new HttpParams().set("languageId", (<Language>data[0]).getId().toString());
                return this.http.get(data[1], {params: httpParams});
            }).catch((error: any) => {
                if (error instanceof HttpErrorResponse && error.status == 404) {
                    isClassifierDeleted = true;
                    return this.entityAuditService.getEntityLastVersion(categoryName, id)
                }
            });

        return Observable.combineLatest(metaFields$, data$, this.languages$)
            .concatMap((data : any) => {
                let serviceResponse = data[1];
                return deserializeClassifier(data[0], serviceResponse, categoryName, data[2], this, this.messageService, isWithChildren, isClassifierDeleted)
            });
    }


    // back-end calls

	private saveClassifiers(modificationType: string, categorySystemName: string, classifiers: Classifier[], isBulk: boolean, raiseEvent: boolean): any {
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIER_SAVE.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName
                });
            });
        let metaFields$: Observable<Array<MetaField>> = this.kbService.getMetaFields(categorySystemName);
        let data$: Observable<any> = this.languages$
            .switchMap(languages => {
                if (isBulk) {
                    const serializedEntities$: any[] = [];
                    classifiers.forEach(classifier => {
                        serializedEntities$.push(serializeEntity(classifier, metaFields$, languages, this.kbService));
                    });
                    return Observable.combineLatest(serializedEntities$);
                } else {
                    return serializeEntity(classifiers[0], metaFields$, languages, this.kbService);
                }
            });
        let serverResponse$: Observable<any> = Observable.combineLatest(url$, data$, this.language$)
            .switchMap((values: any) => {
                let httpParams: HttpParams = new HttpParams().set("languageId", (<Language>values[2]).getId().toString());
                if(isBulk){
                    httpParams = httpParams.set("type", "bulk");
                    httpParams = httpParams.set("raiseEvent", `${raiseEvent}`);
                }
                return modificationType === this.MODIFICATION_TYPE_ADD ?
                    this.http.post(values[0], values[1], {params: httpParams})
                    : this.http.put(values[0], values[1], {params: httpParams});
            });

        return Observable.combineLatest(serverResponse$, metaFields$, this.languages$);
    }



    private loadClassifiersViewFromServer(categorySystemName: string, languageId: number): Observable<Array<ClassifierView>> {
        PreconditionCheck.notNullOrUndefined(categorySystemName);
        let metaFields$: Observable<Array<MetaField>> = this.kbService.getClassifierViewMetaFields();
        let data$ = this.loadClassifiersViewDataFromServer(categorySystemName, languageId);
        return Observable.zip(metaFields$, data$)
            .map((data : any) => deserializeClassifiersView(data[0], data[1]));
    }


    private loadClassifiersViewDataFromServer(categorySystemName: string, languageId: number): Observable<any> {
        PreconditionCheck.notNullOrUndefined(categorySystemName);
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIERS_VIEW_LOAD.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName,
                    "languageId": languageId.toString()
                });
            });
        return url$.flatMap((url: string) => this.http.get(url));
    }


    private loadClassifiersViewByParentFromServer(categorySystemName: string, languageId: number, parentId: number,): Observable<Array<ClassifierView>> {
        let metaFields$: Observable<Array<MetaField>> = this.kbService.getClassifierViewMetaFields();
        let data$ = this.loadClassifiersViewDataByParentFromServer(categorySystemName, languageId, parentId);
        return Observable.zip(metaFields$, data$)
            .map((data : any) => deserializeClassifiersView(data[0], data[1]));
    }


    private loadClassifiersViewDataByParentFromServer(categorySystemName: string, languageId: number, parentId: number,): Observable<any> {
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIERS_VIEW_LOAD_BY_PARENT.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName,
                    "parentId": parentId.toString(),
                    "languageId": languageId.toString()
                });
            });

        return url$.flatMap((url: string) => {
            return this.http.get(url);
        });
    }

    private loadClassifiersViewByFilterFromServer(categorySystemName: string, filter: FilterCriteria, languageId: number): Observable<Array<ClassifierView>> {
        PreconditionCheck.notNullOrUndefined(categorySystemName);
        let filterJsonCriteria = {condition: filter};

        let metaFields$: Observable<Array<MetaField>> = this.kbService.getClassifierViewMetaFields();
        let url$ = this.classifierServiceUri$
            .map((classifierServiceUri: string) => {
                return this.URL_CLASSIFIERS_VIEW_LOAD.replaceTemplate({
                    "serviceUri": classifierServiceUri,
                    "category": categorySystemName,
                    "languageId": languageId.toString()
                });
            });
        let data$ = url$.flatMap((url: string) => {
            let httpParams: HttpParams = new HttpParams()
                .set("filterCriteria", JSON.stringify(filterJsonCriteria));
            return this.http.get(url, {params: httpParams});
        });

        return Observable.zip(metaFields$, data$)
            .map((data : any) => deserializeClassifiersView(data[0], data[1]));
    }


    // utilities


    private getHttpParams(filter: FilterCriteria, paging: PagingOptions): HttpParams {
        const filterJsonCriteria = {condition: filter};
        let httpParams: HttpParams = new HttpParams();
        httpParams = httpParams.set("filterCriteria", JSON.stringify(filterJsonCriteria));
        if (paging) {
            httpParams = httpParams.set("pagingCriteria", JSON.stringify(paging))
        }
        return httpParams;
    }


    private handleErrorDuringDeserializationResponse(error: any, categorySystemName: string): Observable<any> {
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : '';
        console.error('Error during deserialization error:' + errMsg);
        return this.clearCache(categorySystemName);
    }

    private handleError(error: any) {
        let errMsg = (error.message) ? error.message :
            error.status ? `${error.status} - ${error.statusText}` : 'Server error';
        console.error(errMsg);
        return Observable.throw(errMsg);
    }

    private getCacheKey(applicationName: string, categorySystemName: string,
                        isWithChildren: boolean, languageId?: number): string {
        return !languageId ? (`${applicationName}-${categorySystemName}-${isWithChildren ? '-with-children' : '-without-children'}`)
            : `${applicationName}-${categorySystemName}-view-language-${languageId}`;
    }

    private isAsyncLocalStorageSupported(): Observable<boolean> {
        //make one time-cachable event
        let testKey = 'checkLocalStorageSupport';
        return this.storage.setItem(testKey, testKey + Date.now()).flatMap(() => {
            return this.storage.removeItem(testKey).map(() => {
                return true
            })
        }).catch(() => {
            return Observable.of(false);
        });
    }

    private initAsyncLocalStorageSupportChecking(): void {
        this.isAsyncLocalStorageSupported().subscribe((response: boolean) => {
            this.isLocalStorageSupported.next(response);
        });
    }

}

enum CacheItemStatus {
    LOADING = 1,
    LOADED = 2
}
