
import {FilterCriteriaDefault} from "./filter-criteria-default.model";
import {FilterOperator} from "./filter-type.model";

export class IntersectionFilterCriteria extends FilterCriteriaDefault {
    constructor(field: string, operator: FilterOperator, value: Array<number>){
        if(value.length === 0){
            throw new Error("Intersection filter requires at least one value.")
        }
        super(field, operator, value);
    }

    protected getFilterOperatorSerializedValue(): string {
        return "$intersection";
    }

}
