import {Accounting, Language, Money, MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";
import {Observable} from "rxjs/Observable";
import {KbService, MetaField, MetaFieldType} from "@synisys/idm-kb-service-client-js";
import {Entity, EntityDefault, serializeLocalDate} from "@synisys/idm-de-core-frontend";

export function serializeEntity(entity: Entity, metaFields$: Observable<Array<MetaField>>, languages: Array<Language>, kbService: KbService): Observable<any> {
    let entityData: any = {};
    return metaFields$.map((metaFields: any) => {
        metaFields.filter((metaField: MetaField) => !(entity.getId() == null && metaField.getType() === MetaFieldType.INTEGER_IDENTITY))
            .forEach((metaField: MetaField) => {
                let metaFieldName: string = metaField.getSystemName();
                let metaFieldValue: any = entity.getProperty(metaFieldName).value;
                //ToDO must be changed as soon as possible, as subscribe here is unacceptable
                serializeField(metaField, metaFieldValue, languages, kbService).subscribe((serializedField:any) => {
                    entityData[metaFieldName] = serializedField
                });
            });
        return entityData;
    });
}
export function serializeField(metaField: MetaField, fieldValue: any, languages: Array<Language>, kbService: KbService): Observable<any> {
    let metaFieldType: MetaFieldType = metaField.getType();
    switch (metaFieldType) {
        case MetaFieldType.INTEGER_IDENTITY:
        case MetaFieldType.INTEGER_INSTANCE:
        case MetaFieldType.INTEGER:
        case MetaFieldType.DECIMAL:
        case MetaFieldType.BIG_DECIMAL: {
            return (fieldValue || fieldValue == 0) ? Observable.of(+fieldValue) : Observable.of(null);
        }
        case MetaFieldType.CLASSIFIER:
        case MetaFieldType.DOCUMENT:
        case MetaFieldType.PARENT: {
            if (typeof fieldValue == "number") {
                return fieldValue ? Observable.of(+fieldValue) : Observable.of(null);
            } else {
                return fieldValue ? Observable.of(fieldValue.getId()) : Observable.of(null);
            }
        }
        case MetaFieldType.SUB_ENTITY: {
            if (fieldValue && fieldValue.length > 0) {
                let subEntityData: Observable<any>[] = fieldValue.map((entity: EntityDefault) => {
                    return serializeEntity(entity, kbService.getMetaFields(metaField.getCompoundCategorySystemName()), languages, kbService);
                });
               return Observable.combineLatest(...subEntityData).map((item: any[]) => {
                    return item;
                });
            } else {
                return Observable.of([])
            }
        }
        case MetaFieldType.MULTI_SELECT: {
            if (fieldValue && fieldValue.length > 0) {
                return Observable.of(fieldValue.map((classifier: EntityDefault) => {
                    return classifier.getId();
                }));
            }
            return Observable.of([])
        }

        case MetaFieldType.DATE:
        case MetaFieldType.DATE_TIME: {
            return !fieldValue ? Observable.of(null) : Observable.of((<Date>fieldValue).getTime());
        }
        case MetaFieldType.MONEY: {
            return serializeMoney(fieldValue);
        }
        case MetaFieldType.ACCOUNTING: {
            return serializeAccounting(fieldValue);
        }
        case MetaFieldType.BOOLEAN: {
            return Observable.of(!!fieldValue);
        }
        case MetaFieldType.STRING: {
            return fieldValue ? Observable.of(fieldValue) : Observable.of(null);
        }
        case MetaFieldType.MULTILINGUAL_STRING: {
            return Observable.of(serializeMultilingualString(fieldValue, languages));
        }
        case MetaFieldType.LOCAL_DATE: {
            return Observable.of(serializeLocalDate(fieldValue));
        }
        default: {
            throw TypeError("There is no field type " + metaFieldType);
        }
    }
}
export function serializeMultilingualString(multilingualString: MultilingualString, languages: Array<Language>): any {
    let multilingualStringData: any = {};
    languages.forEach((language: Language) => { //TODO must be done via obs
        let languageId = language.getId();
        multilingualStringData[languageId] = multilingualString.getValue(languageId);
    });
    return multilingualStringData;
}

export function serializeMoney(money: Money): Observable<any> {
    if (money) {
        let moneyJson: any = {};
        moneyJson["amount"] = money.amount;
        moneyJson["currencyId"] = money.currencyId;
        return Observable.of(moneyJson)
    }
    return Observable.of(null);
}

export function serializeAccounting(accounting: Accounting): Observable<any> {
    if (accounting) {
        let money$: Observable<any> = serializeMoney(accounting.originalMoney);
        return money$.map((money: any) => {
            let accountingJson: any = {};
            accountingJson["originalMoney"] = money;
            accountingJson["rateToDefault"] = accounting.rateToDefault;
            accountingJson["timestamp"] = accounting.accountingDate ? accounting.accountingDate.getTime() : null;
            return accountingJson;
        });
    }
    return Observable.of(null);
}
