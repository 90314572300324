
import {FilterCriteriaDefault} from "./filter-criteria-default.model";
import {FilterOperator} from "./filter-type.model";

export class RangeFilterCriteria extends FilterCriteriaDefault {
    constructor(field: string, operator: FilterOperator, value: Array<number>){
        if(value.length !== 2){
            throw new Error("Range filter Requires 2 values for min and max values.");
        }
        if(value[0] > value[1]){
            throw new Error("Start Value cannot be greater than end value in range filter.");
        }
        super(field, operator, value);
    }

    protected getFilterOperatorSerializedValue(): string {
        return "$range";
    }


}