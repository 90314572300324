import {FilterOperator} from "./filter-type.model";
import {FilterCriteriaDefault} from "./filter-criteria-default.model";


export class ComparisonFilterCriteria extends FilterCriteriaDefault {
    constructor(field: string, operator: FilterOperator, value: number){
        super(field, operator, value);
    }

    protected getFilterOperatorSerializedValue(): string {
        switch (this.operator){
            case FilterOperator.GTE : {
                return "$gte";
            }
            case FilterOperator.GT : {
                return "$gt";
            }
            case FilterOperator.LTE : {
                return "$lte";
            }
            case FilterOperator.LT : {
                return "$lt";
            }
            default : {
                throw new Error(`Unsupported Operator type ${this.operator} for comparison filters.`);
            }

        }
    }
}