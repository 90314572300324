
export enum FilterOperator {
    IN,
    RANGE,
    LTE,
    LT,
    GTE,
    GT,
    STARTS,
    CONTAINS,
    IS,
    AND,
    OR,
    NOT,
    INTERSECTION
}
