import {NgModule} from "@angular/core";
import {ClassifierHttpService} from "./http/classifier-http.service";
import {ClassifierService} from "./api/classifier.service";
import {EntityAuditService} from "./api/entity-audit.service";
import {EntityAuditHttpService} from "./http/entity-audit-http.service";
import {HttpLocaleService, LocaleService} from '@synisys/idm-locale-service-client-js';

@NgModule({
    providers: [
        {provide: ClassifierService, useClass: ClassifierHttpService},
        {provide: EntityAuditService, useClass: EntityAuditHttpService},
        {provide: LocaleService, useClass: HttpLocaleService}
    ]
})
export class ClassifierServiceModule{}
