import {ValidationsCalculationsData} from "@synisys/idm-validation-calculation-service-client-js";
import {Classifier} from "./classifier.model";
import {ClassifiersResponse} from "./classifiers-response.model";
import {ClassifierResponse} from "./classifier-response.model";
import {ClassifiersResponseMetaData} from "./classifiers-response-meta-data.model";


/**
 * Model class including classifiersResponseMetaData(count) with calculated data items, Validation Errors and Calculations Warnings data for all items
 * Response data populated from server after classifier BULK modification actions (create, update)
 */
export class ClassifiersBulkResponse {


    constructor(private classifiersResponseMetaData: ClassifiersResponseMetaData,
                private classifierResponses: ClassifierResponse[]) { }

    public getClassifiersResponseMetaData(): ClassifiersResponseMetaData {
        return this.classifiersResponseMetaData;
    }

    public getClassifierResponses(): ClassifierResponse[] {
        return this.classifierResponses;
    }
}
