import {EntityDefault, PropertyValue} from "@synisys/idm-de-core-frontend";

/**
 * Model Entity with only id and names
 * Mainly must be used for select-one, many-select and in a similar simple cases where id, name are enough.
 */
export class ClassifierView extends EntityDefault {

    public static VIEW_CLASSIFIER_FIELD_NAME_OF_ID: string = "id";
    public static VIEW_CLASSIFIER_FIELD_NAME_OF_NAME: string = "name";

    public getName(): string {
        return this.getProperty<string>(ClassifierView.VIEW_CLASSIFIER_FIELD_NAME_OF_NAME).value;
    }

    public get id(): number{
        return this.getProperty<number>(ClassifierView.VIEW_CLASSIFIER_FIELD_NAME_OF_ID).value;
    }


    public get name(): string{
        return this.getProperty<string>(ClassifierView.VIEW_CLASSIFIER_FIELD_NAME_OF_NAME).value;
    }

    constructor(properties: Map<string, PropertyValue<any>>){
         super(properties, ClassifierView.VIEW_CLASSIFIER_FIELD_NAME_OF_ID, ClassifierView.VIEW_CLASSIFIER_FIELD_NAME_OF_ID);
    }

    public clone<T=ClassifierView>(): T {
        return this.cloneEntity(ClassifierView);
    }

}
