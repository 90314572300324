import {FilterCriteria} from "./filter-criteria.model";
import {FilterOperator} from "./filter-type.model";


export class NotFilterCriteria implements FilterCriteria {

    protected operator: FilterOperator;

    protected filterCriteria: FilterCriteria;

    constructor(operator: FilterOperator, filterCriteria: FilterCriteria){
        this.operator = operator;
        this.filterCriteria = filterCriteria;
    }

    toJson(): any {
        let filterCriteriaJson = {};
        filterCriteriaJson["$not"] = this.filterCriteria.toJson();
        return filterCriteriaJson;
    }


}
