import "rxjs/add/operator/toPromise";
import {Observable} from "rxjs/Observable";
import {PagingOptions, SortingOptions} from "@synisys/idm-de-core-frontend";
import {ClassifiersResponse} from "../model/classifiers-response.model";
import {ClassifierView} from "../model/classifier-view.model";
import {Classifier} from "../model/classifier.model";
import {ClassifierResponse} from "../model/classifier-response.model";
import {Validation} from "@synisys/idm-validation-calculation-service-client-js";
import {FilterCriteria} from "../helper/filter/filter-criteria.model";
import {Entity} from "@synisys/idm-de-core-frontend/app/shared/api/model/entity.model";
import {ItemPermissions} from "../model/classifier-item-permission.model";
import {ClassifiersBulkResponse} from "../model/classifiers-bulk-response.model";

/**
 * Abstract Class Interface responsible for loading and modifying Classifier item.
 * All CRUD operations Classifier item and items
 *
 * @author Anania.Mikaelyan
 */
export abstract class ClassifierService {

    /**
     * Loads classifiers data (including meta-data) and returns it by observable stream
     * @param categorySystemName category system name of requested classifier
     * @param isWithChildren to load inner classifiers and subEntities or not
     * @param paging this parameter is specified in the case the client wants to get some set of the classifiers.
     *          If the category is cacheable, all the classifiers are loaded and then paging is applied.
     *          Otherwise only the mentioned classifiers are loaded.
     */
    public abstract loadAllClassifiers(categorySystemName: string, isWithChildren?: boolean, paging?: PagingOptions): Observable<ClassifiersResponse>;

    /**
     * Loads classifiers data (including meta-data) and returns it by observable stream
     * @deprecated, please use loadAllClassifiers() instead
     * @param categorySystemName category system name of requested classifier
     * @param paging paging options data
     * @param sorting sorting option data
     * @param filter filter option data
     * @param isWithChildren to load inner classifiers and subEntities or not
     */
    public abstract loadClassifiers(categorySystemName: string, isWithChildren?: boolean, paging?: PagingOptions, sorting?: SortingOptions, filter?: any): Observable<ClassifiersResponse>;

    /**
     * Loads classifiers having ids from the given array. If the classifier cache already exists, it filters the items from the cache,
     * otherwise it calls the server to get the items needed and at the same time calls to load all the classifiers to fill the cache.
     * @param categorySystemName category system name of requested classifier
     * @param filterIds classifier ids
     * @param paging paging options data
     * @param sorting sorting option data
     * @param isWithChildren to load inner classifiers and subEntities or not
     */
    public abstract loadClassifiersByIds(categorySystemName: string, filterIds: Array<number>, isWithChildren?: boolean, paging?: PagingOptions, sorting?: SortingOptions): Observable<ClassifiersResponse>;

    /**
     * Loads classifiers that fulfill the filtering criteria
     * @param categorySystemName category system name of requested classifier
     * @param filterCriteria json representing the filtering criteria
     * @param paging paging options data
     * @param sorting sorting option data
     * @param isWithChildren to load inner classifiers and subEntities or not
     */
    public abstract loadClassifiersByFilter(categorySystemName: string, filterCriteria: FilterCriteria, isWithChildren?: boolean, paging?: PagingOptions, sorting?: SortingOptions): Observable<ClassifiersResponse>;


    /**
     * Loads classifiers data by parent Id (including meta-data) and returns it by observable stream
     * @param categorySystemName category system name of requested classifier
     * @param parentId Id of the parent classifier
     * @param paging paging options data
     * @param sorting sorting option data
     * @param filter filter option data
     * @param isWithChildren to load inner classifiers and subEntities or not
     */
    public abstract loadClassifiersByParent(categorySystemName: string, parentId: number, paging?: PagingOptions, sorting?: SortingOptions, filter  ?: any, isWithChildren?: boolean): Observable<ClassifiersResponse>;


    /**
     * Loads Collection of classifier views (only ids and names) that match the filter criteria and returns it by observable stream.
     * @param categorySystemName category system name of requested classifier
     * @param filter filterCriteria by which we want to filter the data
     */
    public abstract loadClassifiersViewByFilter(categorySystemName: string, filter: FilterCriteria): Observable<Array<ClassifierView>>;

    /**
     * Loads Collection of classifier views (only ids and names) by paging that match the filter criteria and returns it by observable stream.
     * @param categorySystemName category system name of requested classifier
     * @param filter filterCriteria by which we want to filter the data
     * @param paging paging options data
     */
    public abstract loadClassifiersViewByPaging(categorySystemName: string, filterCriteria: FilterCriteria, paging?: PagingOptions): Observable<ClassifierView[]>;

    /**
     * Loads Collection of classifier views (only ids and names) and returns it by observable stream.
     * Mainly must be used for select-one, many-select and in a similar simple cases where id, name are enough.
     * @param categorySystemName category system name of requested classifier
     */
    public abstract loadClassifiersView(categorySystemName: string): Observable<Array<ClassifierView>>;


    /**
     * Loads Collection of classifier views (only ids and names) by parent Id  and returns it by observable stream.
     * Mainly must be used for select-one, many-select and in a similar simple cases where id, name are enough.
     * @param categorySystemName category system name of requested classifier
     * @param parentId Id of the parent classifier
     */
    public abstract loadClassifiersViewByParent(categorySystemName: string, parentId: number): Observable<Array<ClassifierView>>;


    /**
     * If the classifier is cacheable, loads all the classifier of that category, filters to find the one needed and returns it by observable stream.
     * @param categorySystemName category system name of requested classifier
     * @param id identifier of requested classifier which must be loaded
     * @param isWithChildren to load inner classifiers and subEntities or not
     */
    public abstract loadClassifier(categorySystemName: string, id: number, isWithChildren?: boolean): Observable<Classifier>;

    /**
     * Loads classifier data and it's dependencies data and returns it by observable stream
     * @param categorySystemName category system name of requested classifier
     * @param id identifier of requested classifier which must be loaded
     */
    public abstract loadClassifierDependencies(categorySystemName: string, id: number): Observable<Classifier>;

    /**
     * Loads classifers icon ids and represents as map of key: classifierId, value: iconId
     * @param categorySystemName of classifers
     * @param ids of requesting classifiers
     */
    public abstract loadCategoryIconIds(categorySystemName: string, ids: Array<number>): Observable<Map<number, number>>;

    /**
     * Creates new dummy classifier and returns via observable stream
     * @param categorySystemName category system name of new created classifier
     */
    public abstract createDummyClassifier(categorySystemName: string): Observable<Classifier> ;

    /**
     * Creates-Inserts new classifier and returns new created classifier by new id via observable stream
     * if save is successful or validation data if data is not valid
     * @param categorySystemName category system name of new created classifier
     * @param classifier new one that must be persisted
     */
    public abstract create(categorySystemName: string, classifier: Classifier): Observable<ClassifierResponse> ;

    /**
     * Updates classifier and returns classifier via observable stream
     * if save is successful or validation data if data is not valid
     * @param categorySystemName category system name of updating classifier
     * @param classifier the one that must be persisted
     */
    public abstract update(categorySystemName: string, classifier: Classifier): Observable<ClassifierResponse>;
	/**
	 * Creates-Inserts new classifiers and returns new created classifiers by new ids via observable stream
	 * if save is successful or validation data if data is not valid
	 * @param categorySystemName category system name of new created classifiers
	 * @param classifiers List of Classifiers to be created
	 * @param raiseEvent Weather or not events of classifiers deletions should be raised. Default value true.
	 */
	public abstract bulkCreate(categorySystemName: string, classifiers: Classifier[], raiseEvent?: boolean): Observable<ClassifiersBulkResponse>

	/**
	 * Updates classifiers and returns classifiers via observable stream
	 * if save is successful or validation data if data is not valid
	 * @param categorySystemName category system name of updating classifiers
	 * @param classifiers the list of classifiers to be updated
     * @param raiseEvent Weather or not events of classifiers deletions should be raised. Default value true.
	 */
	public abstract bulkUpdate(categorySystemName: string, classifiers: Classifier[], raiseEvent?: boolean): Observable<ClassifiersBulkResponse>
	/**
     * Deleted classifier and return responsible via observable
     * @param categorySystemName  category system name of deleting classifier
     * @param classifier item that must be deleted
     */
    public abstract delete(categorySystemName: string, classifier: Classifier): Observable<any>;


    /**
     * Deleted classifiers
     * @param categorySystemName  category system name of deleting classifier
     * @param ids ids to delete
     * @param raiseEvent Weather or not events of classifiers deletions should be raised. Default value true
     */
    public abstract bulkDelete(categorySystemName: string, ids: number[], raiseEvent?: boolean): Observable<any>;

    /**
     * @deprecated, please use calculateAndValidateClassifier instead
     * Validates the classifier.
     * @param categorySystemName the category system name of the classifier
     * @param classifier the classifier to validate
     */
    public abstract validateClassifier(categorySystemName: string, classifier: Classifier): Observable<Array<Validation>>;

    /**
     * @deprecated, please use calculateAndValidateClassifier instead
     * Validates the field of the classifier.
     * @param categorySystemName the category system name of the classifier
     * @param classifier the classifier the field of which has to be validated
     * @param fieldSystemName
     */
    public abstract validateClassifierField(categorySystemName: string, classifier: Classifier, fieldSystemName: string): Observable<Array<Validation>>;

    /**
     * @param categorySystemName category system name of requested classifier
     */
    public abstract resetCaches(categorySystemName: string): void;

    /**
     * If no parameter is given, clears the whole indexedDB storage and localStorage.
     * If category system name is specified, will clear ths cache keys from local storage that contains it, and the corresponding items from indexedDB
     * @param categorySystemName name of the category the cache of which you want to clear
     */
    public abstract clearCache(categorySystemName?: string): Observable<any>;

    /**
     * Gets tha last modification time of the given category (even if the classifier is saved without any changes, this time if updated)
     * @param categorySystemName
     */
    public abstract getCategoryLastModificationTimeInMillis(categorySystemName: string): Observable<number>;

    /**
     * @deprecated, please use getDocumentAuthInfo() instead
     * Gets authInfo to download  document of classifier
     * @param categorySystemName: classifier category
     * @param id: classifier id
     * @param documentSystemName: document system name
     *
     */
    public abstract getAuthInfo(categorySystemName: string, id: number, documentSystemName: string): Observable<string>;


    /**
     *
     * Gets Document authInfo to download  document of classifier
     * @param categorySystemName: classifier category
     * @param id: classifier id
     * @param documentSystemName: document system name
     *
     */
    public abstract getDocumentAuthInfo(categorySystemName: string, id: number, documentSystemName: string): Observable<string>;

    /**
     *
     * Gets Document authInfo to upload  document of classifier
     * @param categorySystemName: classifier category
     * @param id: classifier id
     * @param documentSystemName: document system name
     *
     */
    public abstract getDocumentAuthInfoForUpload(categorySystemName: string, id: number, documentSystemName: string): Observable<string>;

    /**
     * Gets the category names, which were modified after modifiedAfter(given in millis)
     * @param modifiedAfter
     */
    public abstract getModifiedCategories(modifiedAfter: number): Observable<Array<string>>;


    /**
     * Gets the passed entity name measure values concatenated via spaces.
     * @param entity which name must be return
     * @param separator by which isName fields should be joined
     */
    public abstract getEntityName(categorySystemName: string, entity: Entity, separator?: string): Observable<string>;

    /**
     *
     * Gets individual permission for each given classifier item (permissions can be given per item)
     * @param categorySystemName: classifier category
     * @param itemId: classifier item id for which permission is needed
     *
     */
    public abstract loadItemPermissions(categorySystemName: string, itemId: number): Observable<ItemPermissions>;

}

