import {FilterCriteriaDefault} from "./filter-criteria-default.model";
import {FilterOperator} from "./filter-type.model";


export class IsFilterCriteria extends FilterCriteriaDefault {
    constructor(field: string, operator: FilterOperator, value: number | string){
        super(field, operator, value);
    }

    public toJson() : any {
        let filterCriteriaItemJson = {};
        filterCriteriaItemJson[this.field] = this.value;
        return filterCriteriaItemJson;
    }

    protected getFilterOperatorSerializedValue(): string {
        return "";
    }


}