import {
    EntityBuilder, EntityDefault, PropertiesBuilderAbstract,
    ValueAccessorPropertiesBuilder
} from "@synisys/idm-de-core-frontend";
import {
    Accounting,
    Language,
    Money,
    MultilingualString,
    MultilingualStringBuilder
} from "@synisys/idm-crosscutting-concepts-frontend";
import {
    deserializeValidations,
    ValidationsCalculationsData
} from "@synisys/idm-validation-calculation-service-client-js";
import {KbService, MetaCategoryId, MetaField, MetaFieldId, MetaFieldType} from "@synisys/idm-kb-service-client-js";
import {Classifier} from "../model/classifier.model";
import {ClassifiersResponse} from "../model/classifiers-response.model";
import {ClassifiersResponseMetaData} from "../model/classifiers-response-meta-data.model";
import {ClassifierView} from "../model/classifier-view.model";
import {ClassifierResponse} from "../model/classifier-response.model";
import {Observable} from "rxjs/Observable";
import {FieldDataPair} from "../model/field-data-pair";
import {ClassifierService} from "../api/classifier.service";

import {ClassifierHttpService} from "./classifier-http.service";
import {MessageService} from "@synisys/idm-message-language-service-client-js";
import {ClassifiersBulkResponse} from "../model/classifiers-bulk-response.model";

export const META_FIELD_NAME_IS_DELETED = "isDeleted";
export const DELETED_MESSAGE_KEY = "cs_deleted";
export const DELETED_MESSAGE_DEFAULT_VALUE = "[Deleted]";

export function deserializeClassifierResponse(data: any, metaFields: Array<MetaField>, languages: Array<Language>, categorySystemName: string, classifierService: ClassifierService, messageService: MessageService): Observable<ClassifierResponse> {
    if (data.data) {
        let classifier$: Observable<Classifier> = deserializeClassifier(metaFields, data.data, categorySystemName, languages, classifierService, messageService, true, false);
        let validationsCalculationsData: ValidationsCalculationsData = new ValidationsCalculationsData(deserializeValidations(data.calculationErrorDtoData.validationErrorDtos, metaFields, languages), null, data.data);
        return classifier$.map((item) => {
            return new ClassifierResponse(item, validationsCalculationsData);
        })
    } else {
        let classifier$: Observable<Classifier> = deserializeClassifier(metaFields, data, categorySystemName, languages, classifierService, messageService, true, false);
        return classifier$.map((item) => {
            return new ClassifierResponse(item, new ValidationsCalculationsData([], [], data));
        })
    }
}

export function deserializeClassifiersModificationResponse(metaFields: Array<MetaField>, responseData: any, categorySystemName: string,
                                               languages: Array<Language>, isWithChildren: boolean, classifierService: ClassifierService, messageService: MessageService): Observable<ClassifiersBulkResponse> {
    let deserializedClassifiersResponse$: Observable<ClassifierResponse>[] = [];
    responseData.data.forEach((classifierModificationResponse : any) => {
        deserializedClassifiersResponse$.push(deserializeClassifierResponse(classifierModificationResponse, metaFields, languages, categorySystemName,  classifierService, messageService));
    });

    return Observable.combineLatest(...deserializedClassifiersResponse$).map((items) => {
        return new ClassifiersBulkResponse(new ClassifiersResponseMetaData(responseData.metaData.count), items)
    });

}

export function deserializeClassifiersResponse(metaFields: Array<MetaField>, responseData: any, categorySystemName: string,
                                               languages: Array<Language>, isWithChildren: boolean, classifierService: ClassifierService, messageService: MessageService, isDeleted : boolean): Observable<ClassifiersResponse> {
    let classifiers$: Observable<Classifier[]> =
        deserializeClassifiers(responseData, metaFields, categorySystemName, languages, isWithChildren ? isWithChildren : false, classifierService, messageService, isDeleted);
    return classifiers$.map((item) => {
        return new ClassifiersResponse(new ClassifiersResponseMetaData(responseData.metaData.count), item)
    })
}

export function deserializeClassifiersView(metaFields: Array<MetaField>, data: any): Array<ClassifierView> {
    let classifierViews: Array<ClassifierView> = [];
    let jsonData: any = data.json ? data : data; //done for loading json from file as well
    jsonData.forEach((jsonItem: any) => {
        classifierViews.push(deserializeClassifierView(metaFields, jsonItem));
    });
    return classifierViews;
}

export function deserializeClassifierViews(metaFields$: Observable<MetaField[]>, data: any): Observable<ClassifierView[]> {
    return metaFields$
        .map((metaFields: MetaField[]) => data.map((item: any) => deserializeClassifierView(metaFields, item)));
}

export function deserializeClassifierView(metaFields: Array<MetaField>, jsonItem: any): ClassifierView {
    let propertiesBuilder: PropertiesBuilderAbstract = new ValueAccessorPropertiesBuilder();
    metaFields.forEach((field: MetaField) => {
        let fieldName: string = field.getSystemName();
        let fieldValue: any = getFieldValue(jsonItem, fieldName);
        propertiesBuilder.withNameForValue(fieldName, fieldValue);
    });
    return new ClassifierView(propertiesBuilder.buildMap());
}

export function deserializeDummyClassifier(metaFields: Array<MetaField>, categoryName: string, languages: Language[]): Classifier {
    let entityBuilder = new EntityBuilder();
    let propertyBuilder: PropertiesBuilderAbstract = new ValueAccessorPropertiesBuilder();
    let dummyNames: Map<number, string> = new Map<number, string>();
    let idSystemName: string = (categoryName + ClassifierView.VIEW_CLASSIFIER_FIELD_NAME_OF_ID).toUpperCase();
    let instanceSystemName: string = (categoryName + Classifier.CLASSIFIER_FIELD_NAME_POSTFIX_INSTANCE_ID).toUpperCase();
    languages.forEach((language: Language) => {
        dummyNames.set(language.getId(), "")
    });
    metaFields.forEach(metaField => {
        switch (metaField.getType()) {
            case MetaFieldType.MULTILINGUAL_STRING : {
                propertyBuilder.withNameForValue(metaField.getSystemName(), new MultilingualString(dummyNames));
                break;
            }
            case MetaFieldType.SUB_ENTITY:
            case MetaFieldType.MULTI_SELECT: {
                propertyBuilder.withNameForValue(metaField.getSystemName(), []);
                break;
            }
            case MetaFieldType.INTEGER_IDENTITY: {
                idSystemName = metaField.getSystemName();
                propertyBuilder.withNameForValue(metaField.getSystemName(), null);
                break;
            }
            default: {
                propertyBuilder.withNameForValue(metaField.getSystemName(), null);
                break;
            }
        }
    });
    return new Classifier(propertyBuilder.buildMap(), idSystemName, instanceSystemName);
}

export function deserializeClassifier(metaFields: Array<MetaField>, jsonItem: any, categorySystemName: string,
                                      languages: Language[], classifierService: ClassifierService, messageService: MessageService, isWithChildren: boolean, isDeleted: boolean): Observable<Classifier> {
    let entityBuilder = new EntityBuilder();
    if(isDeleted){
        metaFields.push(new MetaField(new MetaFieldId(new MetaCategoryId(categorySystemName), META_FIELD_NAME_IS_DELETED), null, MetaFieldType.BOOLEAN, "BOOLEAN", false))
    }
    let defaultClassifier: Classifier = entityBuilder.createEntity<Classifier>(Classifier, metaFields);
    return deserializeClassifierWithCompoundFields(metaFields, jsonItem, categorySystemName, languages, classifierService, messageService, isWithChildren, isDeleted)
        .map((deserialized) => {
            deserialized.forEach((item: FieldDataPair) => {
                if (item.dataForField === undefined) {
                    defaultClassifier.getProperty(item.metaField.getSystemName()).value = null;
                } else {
                    defaultClassifier.getProperty(item.metaField.getSystemName()).value = item.dataForField;
                }
            });

            return defaultClassifier;
        })
}

function deserializeClassifierWithCompoundFields(metaFields: Array<MetaField>, jsonItem: any, categorySystemName: string,
                                                 languages: Language[], classifierService: ClassifierService, messageService: MessageService, isWithChildren: boolean, isDeleted: boolean): Observable<FieldDataPair[]> {
    return Observable.combineLatest(
        metaFields.map((field: MetaField): Observable<FieldDataPair> => {
            let systemName = field.getSystemName();
            switch (field.getType()) {
                case MetaFieldType.SUB_ENTITY : {
                    if (getFieldValue(jsonItem, systemName) != null) {
                        let queryParams: any = getFieldValue(jsonItem, systemName);
                        let subEntityObservables: Observable<EntityDefault>[] = queryParams.map((subEntityData: any) => {
                            return (<ClassifierHttpService>classifierService).kbService.getMetaFields(field.getCompoundCategorySystemName()).flatMap((subMetaFields) => {
                                return deserializeSubEntity(subMetaFields, subEntityData, field.getCompoundCategorySystemName(),
                                    languages, isWithChildren, classifierService, (<ClassifierHttpService>classifierService).kbService, messageService);
                            })
                        });
                        if (subEntityObservables.length == 0) {
                            return Observable.of(new FieldDataPair(field, []));
                        }
                        return Observable.combineLatest(...subEntityObservables).map((item: EntityDefault[]) => {
                            return new FieldDataPair(field, item);
                        })
                    }
                    else {
                        return Observable.of(new FieldDataPair(field, []));
                    }
                }

                case MetaFieldType.MULTI_SELECT : {
                    let multiSelectItems = getFieldValue(jsonItem, systemName);
                    if (multiSelectItems) {
                        let classifiersObservables$ = classifierService.loadClassifiersByIds(field.getCompoundCategorySystemName(), multiSelectItems, isWithChildren);
                        return classifiersObservables$.map((classifiers : ClassifiersResponse) => {
                                return new FieldDataPair(field, classifiers.getData())
                            }
                        )
                    } else {
                        return Observable.of(new FieldDataPair(field, []));
                    }
                }

                case MetaFieldType.CLASSIFIER : {
                    let fieldValue = getFieldValue(jsonItem, systemName);
                    if (jsonItem && isWithChildren) {
                        if (fieldValue == null) {
                            return Observable.of(new FieldDataPair(field, null))
                        }
                        if (Number.isInteger(fieldValue)) {
                            return classifierService.loadClassifier(field.getCompoundCategorySystemName(), fieldValue, isWithChildren).map(data => {
                                return new FieldDataPair(field, data);
                            });
                        } else {
                            return classifierService.loadClassifier(field.getCompoundCategorySystemName(), fieldValue._propertiesMap.get(fieldValue._identityField)._value, isWithChildren).map(data => {
                                return new FieldDataPair(field, data);
                            });
                        }
                    } else {
                        return Observable.of(new FieldDataPair(field, fieldValue));
                    }
                }
                case MetaFieldType.MULTILINGUAL_STRING: {
                    if (jsonItem && jsonItem[systemName]) {
                        let isNameForDeletedClassifier = field.getSystemName() === Classifier.CLASSIFIER_FIELD_NAME_OF_NAME && isDeleted;

                        if(isNameForDeletedClassifier){
                            return messageService.getMessage(DELETED_MESSAGE_KEY).map((message: string)=>{
                                let deletedMessage = message ? message : DELETED_MESSAGE_DEFAULT_VALUE;
                                return new FieldDataPair(field, deserializeMultilingualString(jsonItem[systemName], languages, deletedMessage));
                            });
                        } else {
                            return Observable.of(
                                new FieldDataPair(field, deserializeMultilingualString(jsonItem[systemName], languages, null)));
                        }

                    }
                    if (jsonItem && jsonItem._propertiesMap) {
                        return Observable.of(
                            new FieldDataPair(field, new MultilingualString(jsonItem._propertiesMap.get(systemName)._value._values)));
                    }
                }
                case MetaFieldType.DATE:
                case MetaFieldType.DATE_TIME: {
                    let dateStr = getFieldValue(jsonItem, systemName);
                    let date = dateStr ? new Date(+dateStr) : null;
                    return Observable.of(new FieldDataPair(field, date));
                }
                case MetaFieldType.MONEY: {
                    return jsonItem ? Observable.of(new FieldDataPair(field, deserializeMoney(jsonItem[systemName]))) : Observable.of(new FieldDataPair(field, null));
                }
                case MetaFieldType.ACCOUNTING: {
                    return jsonItem ? Observable.of(new FieldDataPair(field, deserializeAccounting(jsonItem[systemName]))) : Observable.of(new FieldDataPair(field, null));
                }
                case MetaFieldType.LOCAL_DATE: {
                    let dateStr = getFieldValue(jsonItem, systemName);
                    let date = (dateStr !== undefined && dateStr !== null) ? new Date(`${dateStr}T00:00:00`) : null;
                    return Observable.of(new FieldDataPair(field, date));
                }
                case MetaFieldType.STRING: {
                    let isNameForDeletedClassifier = field.getSystemName() === Classifier.CLASSIFIER_FIELD_NAME_OF_NAME && isDeleted;
                    if(isNameForDeletedClassifier){
                        return messageService.getMessage(DELETED_MESSAGE_KEY).map((message: string)=>{
                            let deletedMessage = message ? message : DELETED_MESSAGE_DEFAULT_VALUE;
                            return new FieldDataPair(field, deletedMessage+getFieldValue(jsonItem, systemName))
                        });
                    } else {
                        return Observable.of(new FieldDataPair(field, getFieldValue(jsonItem, systemName)));
                    }


                }

                case MetaFieldType.BOOLEAN: {
                    let isDeletedMetaFieldForDeletedClassifier = field.getSystemName() === META_FIELD_NAME_IS_DELETED && isDeleted;
                    if(isDeletedMetaFieldForDeletedClassifier){
                        return Observable.of(new FieldDataPair(field, true));
                    }
                    if(field.getSystemName() === META_FIELD_NAME_IS_DELETED){ //TODO consider case when kb contains own isDeleted metafield
                        return Observable.of(new FieldDataPair(field, false));
                    }
                    return Observable.of(new FieldDataPair(field, getFieldValue(jsonItem, systemName)));
                }

                default: {
                    return Observable.of(new FieldDataPair(field, getFieldValue(jsonItem, systemName)));
                }
            }
        }));
}

function deserializeSubEntity(metaFields: Array<MetaField>, jsonItem: any, categorySystemName: string, languages: Language[],
                              isWithChildren: boolean, classifierService: ClassifierService, kbService: KbService, messageService: MessageService): Observable<EntityDefault> {
    let entityBuilder = new EntityBuilder();
    let entity: EntityDefault = entityBuilder.createEntity<EntityDefault>(Classifier, metaFields);
    return deserializeClassifierWithCompoundFields(metaFields, jsonItem, categorySystemName, languages, classifierService, messageService, isWithChildren, false)
        .map((deserialized) => {
            deserialized.forEach((item: FieldDataPair) => {
                entity.getProperty(item.metaField.getSystemName()).value = item.dataForField;
            });
            return entity;
        });
}

export function deserializeClassifiers(data: any, metaFields: Array<MetaField>, categorySystemName: string, languages: Array<Language>,
                                isWithChildren: boolean, classifierService: ClassifierService, messageService: MessageService,isDeleted : boolean): Observable<Classifier[]> {
    let classifiers: Observable<Classifier>[] = [];
    let jsonData: any = data.data ? data.data : data; //done for loading json from file as well
    jsonData.forEach((jsonItem: any) => {
        classifiers.push(deserializeClassifier(metaFields, jsonItem, categorySystemName, languages, classifierService, messageService, isWithChildren, isDeleted));
    });
    if (classifiers.length == 0) {
        return Observable.of([]);
    }
    return Observable.combineLatest(classifiers);
}



/**
 * Deserializes the multilingual string field value.
 * @param {any} jsonData - The array of multilingual values according to application languages.
 * @param {Array<Language>} languages - The array of available languages in the system.
 * @returns {MultilingualString} the multilingual string object filled with the passed data
 */
export function deserializeMultilingualString(jsonData: any, languages: Array<Language>, deletedMessageValue: string) : MultilingualString {
    let multilingualStringBuilder: MultilingualStringBuilder = MultilingualString.newBuilder();
    languages.forEach((language: Language) => {
        let languageId = language.getId();
        let value = (jsonData && jsonData[languageId]) ? ((deletedMessageValue || '') + jsonData[languageId]) : null ;
        multilingualStringBuilder.withValueForLanguage(languageId, value);
    });
    return multilingualStringBuilder.build();
}

function deserializeMoney(jsonItem: any) {
    return new Money(getFieldValue(jsonItem, "amount"), getFieldValue(jsonItem, "currencyId"));
}

function deserializeAccounting(jsonItem: any) {
    let timestampData = getFieldValue(jsonItem, "timestamp");
    const accountingDate = timestampData ? new Date(+timestampData) : null;
    return new Accounting(getFieldValue(jsonItem, "rateToDefault"), deserializeMoney(jsonItem["originalMoney"]), accountingDate)
}

function getFieldValue(jsonItem: any, systemName: string) {
    if (!jsonItem) {
        //let's hide this :  console.warn(`Couldn't get item ${systemName} value from empty json`);
        return null;
    }
    let isForCachedClassifier : boolean = jsonItem._propertiesMap;

    try {

        // get from backend json
        if (!isForCachedClassifier) {
            return jsonItem[systemName];
        }

        //get from indexDb store
        if (jsonItem._propertiesMap.get(systemName)) {
            return jsonItem._propertiesMap.get(systemName)._value;
        }else{
            console.warn(`Couldn't get item ${systemName} value from indexDB ${jsonItem && jsonItem._propertiesMap}`);
        }

        systemName !== META_FIELD_NAME_IS_DELETED && console.warn(`Couldn't get item ${systemName} value from json ${JSON.stringify(jsonItem)}`);
        return null;
    } catch (error) {
        let errMsg = (error.message) ? error.message : error.status ? `${error.status} - ${error.statusText}` : '';
        console.error('Error getting item value from json from server or from cache'+ errMsg);
        return null;
    }
}



