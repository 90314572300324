
import {FilterOperator} from "./filter-type.model";
import {FilterCriteria} from "./filter-criteria.model";


export abstract class FilterCriteriaDefault implements FilterCriteria{

    /**
     * The name of the field the filters corresponds to
     */
    protected field: string;

    /**
     * The type of the filter (e.g. in, range, gte and  etc.)
     */
    protected operator: FilterOperator;

    /**
     * The values of the filter. The values can vary depending on the type of filter operator.
     * E. g. the in filter values type is array, contains filter value type is string.
     */
    protected value: any;

    constructor(field: string, operator: FilterOperator, value: any){
        this.field = field;
        this.operator = operator;
        this.value = value;
    }


    public toJson(): any {
        let filterCriteriaJson: any = {};
        filterCriteriaJson[this.field] = {};
        filterCriteriaJson[this.field][this.getFilterOperatorSerializedValue()] = this.value;
        return filterCriteriaJson;
    }

    protected abstract getFilterOperatorSerializedValue() : string;
}