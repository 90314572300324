import {MetaField} from "@synisys/idm-kb-service-client-js";
/**
 * Created by Tigran.Mkrtchyan on 4/17/2017.
 */


export class FieldDataPair {
constructor(private field:MetaField, private data:any){}

    get metaField(): MetaField {
        return this.field;
    }

    get dataForField(): any {
        return this.data;
    }
}