import "rxjs/add/operator/toPromise";
import {Observable} from "rxjs/Observable";

import {EntityAuditService} from "../api/entity-audit.service";
import {PreconditionCheck, StringTemplate} from "@synisys/idm-common-util-frontend";
import {Injectable} from "@angular/core";
import {HttpClientWrapper} from "@synisys/idm-authentication-client-js";
import {ApplicationPropertiesService} from "@synisys/idm-application-properties-service-client-js";
import {HttpErrorResponse, HttpParams} from "@angular/common/http";
import {ClassifiersResponse} from "../model/classifiers-response.model";
import {ClassifiersResponseMetaData} from "../model/classifiers-response-meta-data.model";
import {catchError, map, publishReplay, refCount} from "rxjs/operators";
import {KbService, MetaField, MetaFieldType} from "@synisys/idm-kb-service-client-js";
import {Classifier} from "../model/classifier.model";
import {deserializeDummyClassifier} from "./cs-deserialization-helper";
import {LanguageService} from "@synisys/idm-message-language-service-client-js";
import {serializeEntity} from "./cs-serialization-helper";
import {Language, MultilingualString} from "@synisys/idm-crosscutting-concepts-frontend";

@Injectable()
export class EntityAuditHttpService implements EntityAuditService{

    public URL_ENTITIES_LAST_VERSIONS: StringTemplate = StringTemplate.createTemplate`${"serviceUri"}/entity/${"category"}/deleted`; //?instanceIds=253
    private auditServiceUriKey: string = "audit-service-url";
    private auditServiceUri$: Observable<string>;

    public constructor(private http: HttpClientWrapper,
                       private applicationPropertiesService: ApplicationPropertiesService,
                       private kbService: KbService,
                       private languageService: LanguageService) {
        this.auditServiceUri$ = Observable.from(this.applicationPropertiesService.getProperty(this.auditServiceUriKey));

    }
    public  getEntitiesLastVersions(categorySystemName: string, ids: Array<number>): Observable<any>{
        PreconditionCheck.notNullOrUndefined(categorySystemName);
        PreconditionCheck.notNullOrUndefined(ids);

        ids = ids.filter(id => id != null);
        if(!ids.length) {
            return Observable.of(new ClassifiersResponse(new ClassifiersResponseMetaData(0), []));
        }

        let url$ = this.auditServiceUri$.map(auditServiceUri => {
                return this.URL_ENTITIES_LAST_VERSIONS.replaceTemplate({
                    "serviceUri": auditServiceUri,
                    "category": categorySystemName
                });
        });
        return url$.mergeMap(url => {
            let httpParams: HttpParams = new HttpParams().set("instanceIds", ids.join(","));
            return this.http.get(url, {params: httpParams}).mergeMap(response => {
                    let responseData: Array<any> = response && (<any>response).data;
                    if (responseData && responseData.length === ids.length) {
                        return Observable.of(response);
                    } else {
                        return this.addNonExistingEntities(categorySystemName, ids, responseData);
                    }
                });
            });
    }

    public  getEntityLastVersion(categorySystemName: string, id: number): Observable<any>{
        return this.getEntitiesLastVersions(categorySystemName, [id])
            .map((classifiersResponse : any) => {
                if(classifiersResponse.data.length > 0){
                    return (classifiersResponse.data[0]);
                } else{
                  console.warn(`'${categorySystemName}' with '${id}' id was not provided from audit service.`);
                  return null;
                }
            });
    }

    private getNotExitingFieldJson(id: number, categoryName: string): Observable<any> {
        const metaFields$: Observable<Array<MetaField>> = this.kbService.getMetaFields(categoryName).pipe(publishReplay(1), refCount());
        return Observable.combineLatest(this.languageService.getAvailableLanguages(), metaFields$).switchMap(langAndMeta => {
            const languages: Language[] = langAndMeta[0];
            const metaFields: MetaField[] = langAndMeta[1];
            let dummyClassifier: Classifier = deserializeDummyClassifier(metaFields, categoryName, languages);
            return this.kbService.getNameMetaFieldIds(categoryName).switchMap(nameMetaFields => {
                const metaField = metaFields.find(metaFeld => metaFeld.getType() === MetaFieldType.INTEGER_IDENTITY);
                const nameMetaField = metaFields.find(metaFeld => metaFeld.getSystemName() === nameMetaFields[0].getSystemName());
                const nameValue = nameMetaField.getType() === MetaFieldType.STRING ? ""
                    : new MultilingualString(this.createMultilingualString(languages));
                dummyClassifier.getProperty(nameMetaFields[0].getSystemName()).value = nameValue;
                dummyClassifier.getProperty(metaField.getSystemName()).value = id;
                return serializeEntity(dummyClassifier, metaFields$, languages, this.kbService)
            });
        })

    }

    private createMultilingualString(languages: Language[]) {
        let dummyNames: Map<number, string> = new Map<number, string>();
        languages.forEach((language: Language) => {
            dummyNames.set(language.getId(), " ")
        });
        return dummyNames;
    }

    private addNonExistingEntities(categorySystemName: string, ids: Array<number>, responseData?: Array<any>) {
        return this.kbService.getIdentityMetaField(categorySystemName).mergeMap((identityMetaField: MetaField) => {
            return Observable.combineLatest(ids.map(id => {
                let itemFoundAudit = responseData &&
                    responseData.find(item => item[identityMetaField.getSystemName()] === id);
                if(itemFoundAudit) {
                    return Observable.of(itemFoundAudit);
                }
                return this.getNotExitingFieldJson(id, categorySystemName)
            })).map(data => {
                return {data: data, metaData: {count: data.length}}
            })
        });
    }
}

