import {FilterCriteriaDefault} from "./filter-criteria-default.model";
import {FilterOperator} from "./filter-type.model";


export class LikeFilterCriteria extends FilterCriteriaDefault {
    constructor(field: string, operator: FilterOperator, value: string){
        super(field, operator, value);
    }

    protected getFilterOperatorSerializedValue(): string {
        switch (this.operator){
            case FilterOperator.CONTAINS : {
                return "$contains";
            }
            case FilterOperator.STARTS : {
                return "$starts";
            }
            default : {
                throw new Error(`Unsupported Operator type ${this.operator} for like filters.`);
            }

        }
    }

}